<template>
    <div>
        <div class="right-menu shipping-right">
            <subHeader pageName="EMAILS" />

            <tutorialsAction />

            <div class="db_top">
                <div class="db-container">

                    <moduleStats/>

                    <!-- search block starts here -->
                    <div class="formOptionsBox">
                        <div class="searchOptionsWrap">
                            <div class="searchBlock" id="email-search">
                                <form @submit.prevent="">
                                    <input
                                        type="text"
                                        name="search"
                                        id="search"
                                        ref="searchInput"
                                        placeholder="Search"
                                        v-model="searchedItem"
                                        @input="searchEmail()"
                                        :disabled="tourIsActive"
                                    />
                                    <div class="searchIconWrap">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.491 17.49">
                                            <path id="Path_10" data-name="Path 10" d="M16,17.49h0L11,12.5v-.79l-.27-.28a6.512,6.512,0,1,1,.7-.7l.28.27h.79l4.99,5L16,17.489ZM6.5,2A4.5,4.5,0,1,0,11,6.5,4.505,4.505,0,0,0,6.5,2Z" fill="#848484"/>
                                        </svg>
                                    </div>
                                </form>
                            </div>
                            <div class="filterShowTabsRow" v-if="searchedItem.length > 0">
                                <div class="filterShowTabsListSearchedQuery">
                                    <p>Search results for "{{ searchedItem.length > 20 ? searchedItem.substr(0, 20) + "..." : searchedItem }}".</p>
                                </div>
                                <div class="filterShowTabsResetBtn">
                                    <button @click.prevent="clearSearch">Clear</button>
                                </div>
                            </div>
                        </div>
                        <div class="csvBtn">
                            <div class="btn-group">
                                <button type="button" @click="openAllEmailTemplatesModal('add')">
                                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <title/>
                                        <path d="M12.69,12.06a1,1,0,0,1-1.34,0L2.87,4.35A2,2,0,0,1,4,4H20a2,2,0,0,1,1.13.35Z" fill="#fff"/>
                                        <path d="M22,6.26V17a3,3,0,0,1-3,3H5a3,3,0,0,1-3-3V6.26l8.68,7.92a2,2,0,0,0,1.32.49,2,2,0,0,0,1.33-.51Z" fill="#fff"/>
                                    </svg>
                                    Email Templates
                                </button>
                            </div>
                        </div> 
                    </div>
                    <!-- search block ends here -->

                    <!-- data table starts here -->
                    <div class="tableMainWrapBox" style="margin-top: 25px;position: relative;" id="preview-record">
                        <div class="tableScrollWrap" ref="emailsTable">
                            <table>
                                <thead>
                                    <tr>
                                        <!-- <th>
                                            <div class="ctmCheckWrap">
                                                <label class="ctmTableCheckContainer">
                                                    <input 
                                                        type="checkbox"
                                                        @click="toggleIsCheckedAll()"
                                                        :checked="checkedItems.length != 0 && checkedItems.length == emailsList.length"
                                                        :disabled="tourIsActive || this.previewForm.id != ''"
                                                    />
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                        </th> -->
                                        <th>
                                            <div class="ctmCheckWrap">
                                                <div class="ctmTableImgWrap">

                                                </div>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmCheckWrap">
                                                <div class="ctmTableImgWrap">
                                                    <p data-toggle="tooltip" data-placement="top" title="Subject">Subject</p>
                                                </div>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmCheckWrap">
                                                <div class="ctmTableImgWrap">
                                                    <p data-toggle="tooltip" data-placement="top" title="Recipients">Recipients</p>
                                                </div>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Sent At">Sent At</p>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody v-if="emailsLoading">
                                    <tr v-for="i in 5" :key="i">
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <label class="ctmTableCheckContainer">
                                                    <!-- <vue-skeleton-loader
                                                        type="rect"
                                                        :width="17"
                                                        :height="20"
                                                        animation="fade"
                                                    /> -->
                                                </label>
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="150"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="150"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="150"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr v-if="emailsList && emailsList.length == 0">
                                        <td colspan="4" style="text-align:center"><p class="mb-0">No Emails Available</p></td>
                                    </tr>
                                    <tr v-for="(email,index) in emailsList" :key="`listedEmail-${index}`">
                                        
                                        <!-- checkbox block starts here -->
                                        <!-- <td @click="previewEmail(email,index,'input',$event)">
                                            <div class="ctmCheckWrap" data-open="true">
                                                <div style="width: 100%;" data-open="true">
                                                    <div class="ctmCheckWrap" data-open="true">
                                                        <label class="ctmTableCheckContainer" data-open="false" @click.prevent=" email.id != -1 ? checkedItemHandle(email.id) : ''">
                                                            <input type="checkbox" v-model="checkedItems" :value="email.id" :disabled="tourIsActive || email.id == -1" data-open="false">
                                                            <span class="checkmark" data-open="false"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </td> -->
                                        <!-- checkbox block ends here -->

                                        <!-- blank block starts here -->
                                        <td @click.prevent="previewEmail(email,index,'solid',$event)">
                                            <div class="tableBodyCtmTxt" data-open="true">

                                            </div>
                                        </td>
                                        <!-- blank block ends here -->

                                        <!-- email subject block starts here -->
                                        <td @click.prevent="previewEmail(email,index,'solid',$event)">
                                            <div class="tableBodyCtmTxt" data-open="true">
                                                <p data-open="true">{{ email.subject ? email.subject : '--' }}</p>
                                            </div>
                                        </td>
                                        <!-- email subject block ends here -->

                                        <!-- email recipients block starts here -->
                                        <td @click="previewEmail(email,index,'input',$event)">
                                            <div class="ctmDataTableActionBtn" data-open="true">
                                                <el-tooltip class="box-item" effect="dark" content="Grouped Contacts" placement="right">
                                                    <button class="delFunction view" @click.prevent="openEmailRecipientsModal(email,index)" :disabled="tourIsActive" data-open="false">
                                                        <svg baseProfile="tiny" height="24px" id="Layer_1" version="1.2" viewBox="0 0 24 24" width="24px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                                            <g>
                                                                <path d="M12,14c1.381,0,2.631-0.56,3.536-1.465C16.44,11.631,17,10.381,17,9s-0.56-2.631-1.464-3.535C14.631,4.56,13.381,4,12,4   S9.369,4.56,8.464,5.465C7.56,6.369,7,7.619,7,9s0.56,2.631,1.464,3.535C9.369,13.44,10.619,14,12,14z"/>
                                                                <path d="M20,15c0.69,0,1.315-0.279,1.768-0.731c0.453-0.452,0.732-1.077,0.732-1.769c0-0.69-0.279-1.315-0.732-1.768   C21.315,10.279,20.69,10,20,10c-0.691,0-1.316,0.279-1.769,0.732C17.779,11.185,17.5,11.81,17.5,12.5   c0,0.691,0.279,1.316,0.731,1.769S19.309,15,20,15z"/>
                                                                <path d="M20,15.59c-1.331,0-2.332,0.406-2.917,0.968C15.968,15.641,14.205,15,12,15c-2.266,0-3.995,0.648-5.092,1.564   C6.312,15.999,5.3,15.59,4,15.59c-2.188,0-3.5,1.09-3.5,2.182c0,0.545,1.312,1.092,3.5,1.092c0.604,0,1.146-0.051,1.623-0.133   c-0.01,0.091-0.04,0.18-0.04,0.27c0,1,2.406,2,6.417,2c3.762,0,6.417-1,6.417-2c0-0.085-0.011-0.17-0.02-0.255   c0.463,0.073,0.995,0.118,1.603,0.118c2.051,0,3.5-0.547,3.5-1.092C23.5,16.68,22.127,15.59,20,15.59z"/>
                                                                <path d="M4,15c0.69,0,1.315-0.279,1.768-0.732C6.221,13.815,6.5,13.19,6.5,12.5c0-0.689-0.279-1.314-0.732-1.768   C5.315,10.28,4.69,10,4,10c-0.691,0-1.316,0.28-1.769,0.732C1.779,11.186,1.5,11.811,1.5,12.5c0,0.69,0.279,1.315,0.731,1.768   C2.684,14.721,3.309,15,4,15z"/>
                                                            </g>
                                                        </svg>
                                                    </button>
                                                </el-tooltip>
                                            </div>
                                        </td>
                                        <!-- email recipients block ends here -->

                                        <!-- email sent at block starts here -->
                                        <td @click.prevent="previewEmail(email,index,'solid',$event)">
                                            <div class="tableBodyCtmTxt" data-open="true">
                                                <p data-open="true">{{ email.created_at ? new Date(email.created_at).toLocaleString("en-US",{year: 'numeric'}) + "-"+
                                                    new Date(email.created_at).toLocaleString("en-US",{month: 'numeric'}) + "-"+
                                                    new Date(email.created_at).toLocaleString("en-US",{day: 'numeric'})
                                                    + ' | '+ new Date(email.created_at).toLocaleTimeString('en-US') : '--' }}
                                                </p>
                                            </div>
                                        </td>
                                        <!-- email sent at block ends here -->

                                    </tr>

                                    <!-- load more block starts here -->
                                    <tr v-if="isPending">
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <label class="ctmTableCheckContainer">
                                                    <!-- <vue-skeleton-loader
                                                        type="rect"
                                                        :width="17"
                                                        :height="20"
                                                        animation="fade"
                                                    /> -->
                                                </label>
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="150"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="150"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="150"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <!-- load more block ends here -->

                                </tbody>
                            </table>
                        </div>

                        <!-- add/update email form starts here -->
                        <div class="productSlideBox" ref="viewInputForm" :class="previewForm.id ? 'activeRow': ''" >
                            <div class="tableActionBtns" id="email-details-preview">
                                <div class="productTableSideBar">
                                    <div class="row">
                                        <div class="col-md-8" id="header-subject-field">
                                            <div class="viewOrderTitle">
                                                <input 
                                                    type="text" 
                                                    placeholder="Write an email subject" 
                                                    v-model="previewForm.subject" 
                                                    :disabled="tourIsActive"
                                                >
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="orderViewNav">
                                                <ul>
                                                    <el-tooltip class="box-item" effect="dark" content="Expand" placement="top-start">
                                                        <li class="sm-extand-hide">
                                                            <button @click.prevent="expand" :disabled="tourIsActive">
                                                                <svg viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg" id="expand-form">
                                                                    <g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" transform="translate(2 2)">
                                                                        <path d="m16.5 5.5v-4.978l-5.5.014"/>
                                                                        <path d="m16.5.522-6 5.907"/>
                                                                        <path d="m11 16.521 5.5.002-.013-5.5"/>
                                                                        <path d="m16.5 16.429-6-5.907"/>
                                                                        <path d="m.5 5.5v-5h5.5"/>
                                                                        <path d="m6.5 6.429-6-5.907"/>
                                                                        <path d="m6 16.516-5.5.007v-5.023"/>
                                                                        <path d="m6.5 10.5-6 6"/>
                                                                    </g>
                                                                </svg>
                                                            </button>
                                                        </li>
                                                    </el-tooltip>
                                                    <el-tooltip class="box-item" effect="dark" content="Close" placement="top-start">
                                                        <li>
                                                            <button @click.prevent="closePreview" id="closeSide" :disabled="tourIsActive">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="29.446" height="33.409" viewBox="0 0 29.446 33.409">
                                                                    <g id="Group_20906" data-name="Group 20906" transform="translate(362.041 -2185.5)">
                                                                        <path id="Path_57522" data-name="Path 57522" d="M-361.041,2186.648v31.409" transform="translate(0 -0.148)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                                                        <g id="Group_20905" data-name="Group 20905" transform="translate(0 -0.5)">
                                                                            <path id="Path_57523" data-name="Path 57523" d="M-354.018,2202.5h20.265" transform="translate(0 0)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                                                            <path id="Path_57524" data-name="Path 57524" d="M-342.6,2193.747l8,9.15-8,8.85" transform="translate(1 -0.247)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                            </button>
                                                        </li>
                                                    </el-tooltip>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="sectionTabsLink" style="padding: 15px 20px;">
                                    <ul onclick="window.myFunction(event)">
                                        <li v-if="!tourIsActive">
                                            <a class="active" id="category-detail-navbtn" href="#emailDetails">Email Info</a>
                                        </li>
                                    </ul>
                                    <div class="updateBtnBox" v-if="emailIsSaving">
                                        <loaderBtn/>
                                    </div>
                                    <div class="updateBtnBox" id="send-email-btn" v-else>
                                        <button 
                                            form="email-information" 
                                            button="submit" 
                                            v-if="previewForm.id == -1" 
                                            :disabled="tourIsActive"
                                        >
                                            Send
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="productTableInfoBlock">
                                <div class="productTableInfoWhiteBox" id="emailDetails">
                                    <ValidationObserver ref="emailObserver">
                                        <form id="email-information" @submit.prevent="validate('preview')">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <ValidationProvider name="subject" :rules="{required:true,max:250}" v-slot="{ errors }">
                                                        <div class="tableFromFieldBox">
                                                            <label for="subject-field">Subject<span>*</span></label>
                                                            <div class="tableFromFieldItem">
                                                                <input 
                                                                    type="text" 
                                                                    name="subject-field" 
                                                                    id="subject-field" 
                                                                    placeholder="Subject" 
                                                                    v-model="previewForm.subject" 
                                                                    :disabled="previewForm.id != -1 || tourIsActive"
                                                                >
                                                                <span class="text-danger">{{ errors[0] }}</span>
                                                            </div>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-md-12">
                                                    <ValidationProvider name="email type" rules="required" v-slot="{ errors }">
                                                        <div class="tableFromFieldBox">
                                                            <label for="email-type-field">Email Type<span>*</span></label>
                                                            <div class="vueCtmSelectBlock">
                                                                <select 
                                                                    v-model="previewForm.type" 
                                                                    name="email-type-field" 
                                                                    id="email-type-field" 
                                                                    :disabled="previewForm.id != -1 || tourIsActive"
                                                                >
                                                                    <option :value="emailType" v-for="(emailType,index) in emailTypes" :key="index">
                                                                        {{ emailType }}
                                                                    </option>
                                                                </select>
                                                                <span class="text-danger">{{ errors[0] }}</span>
                                                            </div>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                            </div>
                                        </form>
                                    </ValidationObserver>
                                </div>
                                <div class="productTableInfoWhiteBox" id="emailTemplate">
                                    <div class="productTableInfoWhiteBoxTitle groups">
                                        <h5>Template</h5>
                                        <div class="updateBtnBox" id="template-selection-btn">
                                            <button @click="openTemplateSelectionModal()" v-if="previewForm.id == -1" :disabled="tourIsActive">Choose Template</button>
                                        </div>
                                    </div>
                                    <div class="addOrderTable" v-if="previewForm.id == -1" id="email-template-box">
                                        <EmailEditor
                                            ref="emailPreviewTemplateEditor"
                                            @load="emailPreviewEmailEditorLoaded"
                                            @ready="emailPreviewEmailEditorReady"
                                        />
                                    </div>
                                    <div class="addOrderTable" v-html="previewForm.template_html" v-else>

                                    </div>
                                </div>
                                <!-- contacts section -->
                                <div class="productTableInfoWhiteBox" id="contacts-table">
                                    <div class="productTableInfoWhiteBoxTitle groups">
                                        <h5>Contacts</h5>
                                        <div class="updateBtnBox" id="contacts-add-btn">
                                            <button @click="openContactsModal()" v-if="previewForm.id == -1" :disabled="tourIsActive">Add Contacts</button>
                                        </div>
                                    </div>
                                    <div class="addOrderTable">
                                        <table>
                                            <thead>
                                                <tr v-if="previewForm.id == -1">
                                                    <th>Full Name</th>
                                                    <th>Email</th>
                                                    <th>Phone</th>
                                                    <th>City</th>
                                                    <th>Action</th>
                                                </tr>
                                                <tr v-else>
                                                    <th>Full Name</th>
                                                    <th>Email</th>
                                                    <th>Phone</th>
                                                    <th>City</th>
                                                    <th>Status</th>
                                                    <th>Description</th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="previewForm.id == -1">
                                                <tr v-for="(contact, index) in previewForm.contacts" :key="`contact-${index}`">
                                                    <td>
                                                        <div class="productOrderTableTxt">
                                                            <p>{{ contact.first_name + " " + contact.last_name }}</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="productOrderTableTxt">
                                                            <p>{{ contact.email }}</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="productOrderTableTxt">
                                                            <p>{{ contact.mobile_no ? contact.mobile_no : '--' }}</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="productOrderTableTxt">
                                                            <p>{{ contact.city ? contact.city.name : '--' }}</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="ctmDataTableActionBtn">
                                                            <button class="delFunction" @click="removeFormContactHandle(contact,index)">
                                                                <i aria-hidden="true" class="fa fa-trash-o"></i>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody v-else>
                                                <tr v-for="(contact, index) in previewForm.contacts" :key="`updateContact-${index}`">
                                                    <td>
                                                        <div class="productOrderTableTxt">
                                                            <p>{{ contact.first_name + " " + contact.last_name }}</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="productOrderTableTxt">
                                                            <p>{{ contact.email }}</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="productOrderTableTxt">
                                                            <p>{{ contact.mobile_no ? contact.mobile_no : '--' }}</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="productOrderTableTxt">
                                                            <p>{{ contact.city ? contact.city.name : '--' }}</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="productOrderTableTxt">
                                                            <p class="Paid" v-if="contact.status == 'sent'">Sent</p>
                                                            <p class="Cancelled" v-else>Droppped</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="productOrderTableTxt">
                                                            <p>{{ contact.description ? contact.description : '--' }}</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <!-- groups section -->
                                <div class="productTableInfoWhiteBox" id="groups-table">
                                    <div class="productTableInfoWhiteBoxTitle groups">
                                        <h5>Groups</h5>
                                        <div class="updateBtnBox" id="groups-add-btn">
                                            <button @click="openGroupsModal()" v-if="previewForm.id == -1" :disabled="tourIsActive">Add Groups</button>
                                        </div>
                                    </div>
                                    <div class="addOrderTable">
                                        <table>
                                            <thead>
                                                <tr v-if="previewForm.id == -1">
                                                    <th>Name</th>
                                                    <th>Contacts</th>
                                                    <th>Action</th>
                                                </tr>
                                                <tr v-else>
                                                    <th>Name</th>
                                                    <th>Description</th>
                                                    <th>Contacts</th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="previewForm.id == -1">
                                                <tr v-for="(group, index) in previewForm.groups" :key="`contact-${index}`">
                                                    <td>
                                                        <div class="productOrderTableTxt">
                                                            <p>{{ group.name }}</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="ctmDataTableActionBtn">
                                                            <el-tooltip class="box-item" effect="dark" content="Grouped Contacts" placement="top">
                                                                <button class="delFunction view" @click.prevent="openGroupedContactsModal(group,index)" :disabled="tourIsActive" data-open="false">
                                                                    <svg baseProfile="tiny" height="24px" id="Layer_1" version="1.2" viewBox="0 0 24 24" width="24px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                                                        <g>
                                                                            <path d="M12,14c1.381,0,2.631-0.56,3.536-1.465C16.44,11.631,17,10.381,17,9s-0.56-2.631-1.464-3.535C14.631,4.56,13.381,4,12,4   S9.369,4.56,8.464,5.465C7.56,6.369,7,7.619,7,9s0.56,2.631,1.464,3.535C9.369,13.44,10.619,14,12,14z"/>
                                                                            <path d="M20,15c0.69,0,1.315-0.279,1.768-0.731c0.453-0.452,0.732-1.077,0.732-1.769c0-0.69-0.279-1.315-0.732-1.768   C21.315,10.279,20.69,10,20,10c-0.691,0-1.316,0.279-1.769,0.732C17.779,11.185,17.5,11.81,17.5,12.5   c0,0.691,0.279,1.316,0.731,1.769S19.309,15,20,15z"/>
                                                                            <path d="M20,15.59c-1.331,0-2.332,0.406-2.917,0.968C15.968,15.641,14.205,15,12,15c-2.266,0-3.995,0.648-5.092,1.564   C6.312,15.999,5.3,15.59,4,15.59c-2.188,0-3.5,1.09-3.5,2.182c0,0.545,1.312,1.092,3.5,1.092c0.604,0,1.146-0.051,1.623-0.133   c-0.01,0.091-0.04,0.18-0.04,0.27c0,1,2.406,2,6.417,2c3.762,0,6.417-1,6.417-2c0-0.085-0.011-0.17-0.02-0.255   c0.463,0.073,0.995,0.118,1.603,0.118c2.051,0,3.5-0.547,3.5-1.092C23.5,16.68,22.127,15.59,20,15.59z"/>
                                                                            <path d="M4,15c0.69,0,1.315-0.279,1.768-0.732C6.221,13.815,6.5,13.19,6.5,12.5c0-0.689-0.279-1.314-0.732-1.768   C5.315,10.28,4.69,10,4,10c-0.691,0-1.316,0.28-1.769,0.732C1.779,11.186,1.5,11.811,1.5,12.5c0,0.69,0.279,1.315,0.731,1.768   C2.684,14.721,3.309,15,4,15z"/>
                                                                        </g>
                                                                    </svg>
                                                                </button>
                                                            </el-tooltip>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="ctmDataTableActionBtn">
                                                            <button class="delFunction" @click="removeFormGroupHandle(group,index)">
                                                                <i aria-hidden="true" class="fa fa-trash-o"></i>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody v-else>
                                                <tr v-for="(listedGroup, index) in previewForm.groups" :key="`group-${index}`">
                                                    <td>
                                                        <div class="productOrderTableTxt">
                                                            <p>{{ listedGroup.group.name }}</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="productOrderTableTxt">
                                                            <p>{{ listedGroup.group.description ? listedGroup.group.description : "--" }}</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="ctmDataTableActionBtn">
                                                            <el-tooltip class="box-item" effect="dark" content="Grouped Contacts" placement="top">
                                                                <button class="delFunction view" @click.prevent="openGroupedContactsModal(listedGroup,index)" :disabled="tourIsActive" data-open="false">
                                                                    <svg baseProfile="tiny" height="24px" id="Layer_1" version="1.2" viewBox="0 0 24 24" width="24px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                                                        <g>
                                                                            <path d="M12,14c1.381,0,2.631-0.56,3.536-1.465C16.44,11.631,17,10.381,17,9s-0.56-2.631-1.464-3.535C14.631,4.56,13.381,4,12,4   S9.369,4.56,8.464,5.465C7.56,6.369,7,7.619,7,9s0.56,2.631,1.464,3.535C9.369,13.44,10.619,14,12,14z"/>
                                                                            <path d="M20,15c0.69,0,1.315-0.279,1.768-0.731c0.453-0.452,0.732-1.077,0.732-1.769c0-0.69-0.279-1.315-0.732-1.768   C21.315,10.279,20.69,10,20,10c-0.691,0-1.316,0.279-1.769,0.732C17.779,11.185,17.5,11.81,17.5,12.5   c0,0.691,0.279,1.316,0.731,1.769S19.309,15,20,15z"/>
                                                                            <path d="M20,15.59c-1.331,0-2.332,0.406-2.917,0.968C15.968,15.641,14.205,15,12,15c-2.266,0-3.995,0.648-5.092,1.564   C6.312,15.999,5.3,15.59,4,15.59c-2.188,0-3.5,1.09-3.5,2.182c0,0.545,1.312,1.092,3.5,1.092c0.604,0,1.146-0.051,1.623-0.133   c-0.01,0.091-0.04,0.18-0.04,0.27c0,1,2.406,2,6.417,2c3.762,0,6.417-1,6.417-2c0-0.085-0.011-0.17-0.02-0.255   c0.463,0.073,0.995,0.118,1.603,0.118c2.051,0,3.5-0.547,3.5-1.092C23.5,16.68,22.127,15.59,20,15.59z"/>
                                                                            <path d="M4,15c0.69,0,1.315-0.279,1.768-0.732C6.221,13.815,6.5,13.19,6.5,12.5c0-0.689-0.279-1.314-0.732-1.768   C5.315,10.28,4.69,10,4,10c-0.691,0-1.316,0.28-1.769,0.732C1.779,11.186,1.5,11.811,1.5,12.5c0,0.69,0.279,1.315,0.731,1.768   C2.684,14.721,3.309,15,4,15z"/>
                                                                        </g>
                                                                    </svg>
                                                                </button>
                                                            </el-tooltip>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- add/update email form ends here -->

                    </div>
                    <!-- data table ends here -->
                     
                </div>
            </div>

            <!-- contacts selection modal starts here -->
            <div class="modalBg" :class="contactsModal.isOpen ? 'modal-active' : ''">
                <div class="modalLayers" @click="closeContactsModal()"></div>
                <div class="modalBody">
                    <div class="addProductTitle">
                        <h3>Contacts</h3>
                    </div>
                    <div class="searchProductForm" style="margin-bottom:0px !important">
                        <input
                            type="search"
                            name="searchContact"
                            id="searchContact"
                            placeholder="Search contact"
                            v-model="searchedContact"
                            @input="searchContacts('search',1)"
                        />
                    </div>
                    <div class="oderSlideBox productSideBox" style="position: relative; top: 15px; right: 0; width: 100%; min-height: 400px; max-height: 400px;">
                        <div id="London" class="tabcontent">
                            <div class="content-table">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>
                                                <div class="ctmCheckWrap">
                                                    <!-- <label class="ctmTableCheckContainer">
                                                        <input type="checkbox"/>
                                                        <span class="checkmark" style="width: 19px; height:18px"></span>
                                                    </label> -->
                                                    <div class="ctmTableImgWrap">
                                                        <p data-toggle="tooltip" data-placement="top" title="Contact Image">Image</p>
                                                    </div>
                                                </div>
                                            </th>
                                            <th>
                                                <div class="ctmCheckWrap">
                                                    <div class="ctmTableImgWrap">
                                                        <p data-toggle="tooltip" data-placement="top" title="Contact First Name">
                                                            First Name
                                                        </p>
                                                    </div>
                                                </div>
                                            </th>
                                            <th>
                                                <div class="ctmCheckWrap">
                                                    <div class="ctmTableImgWrap">
                                                        <p data-toggle="tooltip" data-placement="top" title="Contact Last Name">
                                                            Last Name
                                                        </p>
                                                    </div>
                                                </div>
                                            </th>
                                            <th>
                                                <div class="ctmCheckWrap">
                                                    <div class="ctmTableImgWrap">
                                                        <p data-toggle="tooltip" data-placement="top" title="Contact Email">
                                                            Email
                                                        </p>
                                                    </div>
                                                </div>
                                            </th>
                                            <th>
                                                <div class="ctmCheckWrap">
                                                    <div class="ctmTableImgWrap">
                                                        <p data-toggle="tooltip" data-placement="top" title="Contact Phone">
                                                            Phone
                                                        </p>
                                                    </div>
                                                </div>
                                            </th>
                                            <th>
                                                <div class="ctmCheckWrap">
                                                    <div class="ctmTableImgWrap">
                                                        <p data-toggle="tooltip" data-placement="top" title="Contact City">
                                                            City
                                                        </p>
                                                    </div>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="contactsSearchLoader">
                                        <tr v-for="i in 5" :key="`contact-load-${i}`">
                                            <td>
                                                <div class="ctmCheckWrap">
                                                    <vue-skeleton-loader
                                                        type="rect"
                                                        :width="20"
                                                        :height="20"
                                                        animation="fade"
                                                    />
                                                    <div class="ctmDataTableImgBox">
                                                        <vue-skeleton-loader
                                                            type="circle"
                                                            :width="36"
                                                            :height="36"
                                                            animation="fade"
                                                        />
                                                    </div> 
                                                </div>
                                            </td>
                                            <td class="mainTableTitle">
                                                <div class="editFieldTitle">
                                                    <vue-skeleton-loader
                                                        type="rect"
                                                        :width="100"
                                                        :height="20"
                                                        animation="fade"
                                                    />
                                                </div>
                                            </td>
                                            <td class="mainTableTitle">
                                                <div class="editFieldTitle">
                                                    <vue-skeleton-loader
                                                        type="rect"
                                                        :width="100"
                                                        :height="20"
                                                        animation="fade"
                                                    />
                                                </div>
                                            </td>
                                            <td class="mainTableTitle">
                                                <div class="editFieldTitle">
                                                    <vue-skeleton-loader
                                                        type="rect"
                                                        :width="100"
                                                        :height="20"
                                                        animation="fade"
                                                    />
                                                </div>
                                            </td>
                                            <td class="mainTableTitle">
                                                <div class="editFieldTitle">
                                                    <vue-skeleton-loader
                                                        type="rect"
                                                        :width="100"
                                                        :height="20"
                                                        animation="fade"
                                                    />
                                                </div>
                                            </td>
                                            <td class="mainTableTitle">
                                                <div class="editFieldTitle">
                                                    <vue-skeleton-loader
                                                        type="rect"
                                                        :width="100"
                                                        :height="20"
                                                        animation="fade"
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr v-if="contactsModal.list.length == 0">
                                            <td colspan="5" style="text-align:center"><p class="mb-0">No Contacts Available</p></td>
                                        </tr>
                                        <tr v-for="(listedContact,index) in contactsModal.list" :key="`listedContact-${index}`">
                                            <td>
                                                <div class="ctmCheckWrap">
                                                    <label class="ctmTableCheckContainer">
                                                        <input 
                                                            type="checkbox"
                                                            v-model="contactsModal.selectedContacts"
                                                            :value="listedContact"
                                                        />
                                                        <span class="checkmark" style="width: 19px; height:18px"></span>
                                                    </label>
                                                    <div class="ctmDataTableImgBox">
                                                        <img
                                                            :src="generateContactImageSrc(listedContact) ? generateContactImageSrc(listedContact) : '/images/customer_noImage.png'"
                                                            alt=""
                                                            data-open="true"
                                                            @error="handleImageError"
                                                        >
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{{ listedContact.first_name }}</td>
                                            <td>{{ listedContact.last_name }}</td>
                                            <td>{{ listedContact.email }}</td>
                                            <td>{{ listedContact.mobile_no ? listedContact.mobile_no : '--' }}</td>
                                            <td>{{ listedContact.city ? listedContact.city.name : '--' }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <hr>

                    <el-pagination
                        background
                        layout="prev, pager, next"
                        :page-count="contactsPagination.total"
                        @current-change="contactsPaginateChangeHandle"
                        class="mt-4"
                    />

                    <hr>

                    <div class="modalCloseIcon">
                        <button @click="closeContactsModal()">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                                <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <!-- contacts selection modal ends here -->

            <!-- groups selection modal starts here -->
            <div class="modalBg" :class="groupsModal.isOpen ? 'modal-active' : ''">
                <div class="modalLayers" @click="closeGroupsModal()"></div>
                <div class="modalBody">
                    <div class="addProductTitle">
                        <h3>Groups</h3>
                    </div>
                    <div class="searchProductForm" style="margin-bottom:0px !important">
                        <input
                            type="search"
                            name="searchGroup"
                            id="searchGroup"
                            placeholder="Search group"
                            v-model="searchedGroup"
                            @input="searchGroups('search',1)"
                        />
                    </div>
                    <div class="oderSlideBox productSideBox" style="position: relative; top: 15px; right: 0; width: 100%; min-height: 400px; max-height: 400px;">
                        <div id="London" class="tabcontent">
                            <div class="content-table">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>
                                                <div class="ctmCheckWrap">
                                                    <label class="ctmTableCheckContainer">
                                                        <input type="checkbox"/>
                                                        <span class="checkmark" style="width: 19px; height:18px"></span>
                                                    </label>
                                                </div>
                                            </th>
                                            <th>
                                                <div class="ctmCheckWrap">
                                                    <div class="ctmTableImgWrap">
                                                        <p data-toggle="tooltip" data-placement="top" title="Contact City">
                                                            Name
                                                        </p>
                                                    </div>
                                                </div>
                                            </th>
                                            <th>
                                                <div class="ctmCheckWrap">
                                                    <div class="ctmTableImgWrap">
                                                        <p data-toggle="tooltip" data-placement="top" title="Contact City">
                                                            Contacts
                                                        </p>
                                                    </div>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="groupsSearchLoader">
                                        <tr v-for="i in 5" :key="`group-load-${i}`">
                                            <td>
                                                <div class="ctmCheckWrap">
                                                    <vue-skeleton-loader
                                                        type="rect"
                                                        :width="20"
                                                        :height="20"
                                                        animation="fade"
                                                    /> 
                                                </div>
                                            </td>
                                            <td class="mainTableTitle">
                                                <div class="editFieldTitle">
                                                    <vue-skeleton-loader
                                                        type="rect"
                                                        :width="100"
                                                        :height="20"
                                                        animation="fade"
                                                    />
                                                </div>
                                            </td>
                                            <td class="mainTableTitle">
                                                <div class="editFieldTitle">
                                                    <vue-skeleton-loader
                                                        type="rect"
                                                        :width="100"
                                                        :height="20"
                                                        animation="fade"
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr v-if="groupsModal.list.length == 0">
                                            <td colspan="5" style="text-align:center"><p class="mb-0">No Groups Available</p></td>
                                        </tr>
                                        <tr v-for="(listedGroup,index) in groupsModal.list" :key="`listedGroup-${index}`">
                                            <td>
                                                <div class="ctmCheckWrap">
                                                    <label class="ctmTableCheckContainer">
                                                        <input 
                                                            type="checkbox"
                                                            v-model="groupsModal.selectedGroups"
                                                            :value="listedGroup"
                                                        />
                                                        <span class="checkmark" style="width: 19px; height:18px"></span>
                                                    </label>
                                                </div>
                                            </td>
                                            <td>{{ listedGroup.name }}</td>
                                            <td>
                                                <div class="ctmDataTableActionBtn">
                                                    <el-tooltip class="box-item" effect="dark" content="Grouped Contacts" placement="top">
                                                        <button class="delFunction view" @click.prevent="openGroupedContactsModal(listedGroup,index)" :disabled="tourIsActive" data-open="false">
                                                            <svg baseProfile="tiny" height="24px" id="Layer_1" version="1.2" viewBox="0 0 24 24" width="24px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                                                <g>
                                                                    <path d="M12,14c1.381,0,2.631-0.56,3.536-1.465C16.44,11.631,17,10.381,17,9s-0.56-2.631-1.464-3.535C14.631,4.56,13.381,4,12,4   S9.369,4.56,8.464,5.465C7.56,6.369,7,7.619,7,9s0.56,2.631,1.464,3.535C9.369,13.44,10.619,14,12,14z"/>
                                                                    <path d="M20,15c0.69,0,1.315-0.279,1.768-0.731c0.453-0.452,0.732-1.077,0.732-1.769c0-0.69-0.279-1.315-0.732-1.768   C21.315,10.279,20.69,10,20,10c-0.691,0-1.316,0.279-1.769,0.732C17.779,11.185,17.5,11.81,17.5,12.5   c0,0.691,0.279,1.316,0.731,1.769S19.309,15,20,15z"/>
                                                                    <path d="M20,15.59c-1.331,0-2.332,0.406-2.917,0.968C15.968,15.641,14.205,15,12,15c-2.266,0-3.995,0.648-5.092,1.564   C6.312,15.999,5.3,15.59,4,15.59c-2.188,0-3.5,1.09-3.5,2.182c0,0.545,1.312,1.092,3.5,1.092c0.604,0,1.146-0.051,1.623-0.133   c-0.01,0.091-0.04,0.18-0.04,0.27c0,1,2.406,2,6.417,2c3.762,0,6.417-1,6.417-2c0-0.085-0.011-0.17-0.02-0.255   c0.463,0.073,0.995,0.118,1.603,0.118c2.051,0,3.5-0.547,3.5-1.092C23.5,16.68,22.127,15.59,20,15.59z"/>
                                                                    <path d="M4,15c0.69,0,1.315-0.279,1.768-0.732C6.221,13.815,6.5,13.19,6.5,12.5c0-0.689-0.279-1.314-0.732-1.768   C5.315,10.28,4.69,10,4,10c-0.691,0-1.316,0.28-1.769,0.732C1.779,11.186,1.5,11.811,1.5,12.5c0,0.69,0.279,1.315,0.731,1.768   C2.684,14.721,3.309,15,4,15z"/>
                                                                </g>
                                                            </svg>
                                                        </button>
                                                    </el-tooltip>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <hr>

                    <el-pagination
                        background
                        layout="prev, pager, next"
                        :page-count="groupsPagination.total"
                        @current-change="groupsPaginateChangeHandle"
                        class="mt-4"
                    />

                    <hr>

                    <div class="modalCloseIcon">
                        <button @click="closeGroupsModal()">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                                <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <!-- groups selection modal ends here -->

            <!-- grouped contact modal starts here -->
            <div class="modalBg" :class="groupedContacts.isOpen ? 'modal-active' : ''">
                <div class="modalLayers" @click="groupedContacts.isOpen = false"></div>
                <div class="modalBody">
                    <div class="oderSlideBox productSideBox" style="position: relative; top: 15px; right: 0; width: 100%; min-height: 400px; max-height: 400px;">
                        <div id="London" class="tabcontent">
                            <div class="contaient-div horizontal-scroll-fix">
                                <div class="customer-detail customer-detail-product contacts">
                                    <h2>Grouped Contacts</h2>
                                </div>
                            </div>
                            <div class="content-table">
                                <table class="table">
                                    <thead>
                                        <tr v-if="previewForm.id == -1">
                                            <th>
                                                <div class="ctmCheckWrap">
                                                    <div class="ctmTableImgWrap">
                                                        <p data-toggle="tooltip" data-placement="top" title="Contact First Name">
                                                            First Name
                                                        </p>
                                                    </div>
                                                </div>
                                            </th>
                                            <th>
                                                <div class="ctmCheckWrap">
                                                    <div class="ctmTableImgWrap">
                                                        <p data-toggle="tooltip" data-placement="top" title="Contact Last Name">
                                                            Last Name
                                                        </p>
                                                    </div>
                                                </div>
                                            </th>
                                            <th>
                                                <div class="ctmCheckWrap">
                                                    <div class="ctmTableImgWrap">
                                                        <p data-toggle="tooltip" data-placement="top" title="Contact Email">
                                                            Email
                                                        </p>
                                                    </div>
                                                </div>
                                            </th>
                                            <th>
                                                <div class="ctmCheckWrap">
                                                    <div class="ctmTableImgWrap">
                                                        <p data-toggle="tooltip" data-placement="top" title="Contact Phone">
                                                            Phone
                                                        </p>
                                                    </div>
                                                </div>
                                            </th>
                                            <th>
                                                <div class="ctmCheckWrap">
                                                    <div class="ctmTableImgWrap">
                                                        <p data-toggle="tooltip" data-placement="top" title="Contact City">
                                                            City
                                                        </p>
                                                    </div>
                                                </div>
                                            </th>
                                        </tr>
                                        <tr v-else>
                                            <th>
                                                <div class="ctmCheckWrap">
                                                    <div class="ctmTableImgWrap">
                                                        <p data-toggle="tooltip" data-placement="top" title="Contact Full Name">
                                                            Full Name
                                                        </p>
                                                    </div>
                                                </div>
                                            </th>
                                            <th>
                                                <div class="ctmCheckWrap">
                                                    <div class="ctmTableImgWrap">
                                                        <p data-toggle="tooltip" data-placement="top" title="Contact Email">
                                                            Email
                                                        </p>
                                                    </div>
                                                </div>
                                            </th>
                                            <th>
                                                <div class="ctmCheckWrap">
                                                    <div class="ctmTableImgWrap">
                                                        <p data-toggle="tooltip" data-placement="top" title="Contact Phone">
                                                            Phone
                                                        </p>
                                                    </div>
                                                </div>
                                            </th>
                                            <th>
                                                <div class="ctmCheckWrap">
                                                    <div class="ctmTableImgWrap">
                                                        <p data-toggle="tooltip" data-placement="top" title="Contact City">
                                                            City
                                                        </p>
                                                    </div>
                                                </div>
                                            </th>
                                            <th>
                                                <div class="ctmCheckWrap">
                                                    <div class="ctmTableImgWrap">
                                                        <p data-toggle="tooltip" data-placement="top" title="Contact Status">
                                                            Status
                                                        </p>
                                                    </div>
                                                </div>
                                            </th>
                                            <th>
                                                <div class="ctmCheckWrap">
                                                    <div class="ctmTableImgWrap">
                                                        <p data-toggle="tooltip" data-placement="top" title="Contact Description">
                                                            Description
                                                        </p>
                                                    </div>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="previewForm.id == -1">
                                        <tr v-for="(groupedContact,index) in groupedContacts.contacts" :key="`groupedContact-${index}`">
                                            
                                            <td>
                                                <div class="tableBodyCtmTxt">
                                                    <p>{{ groupedContact.contact.first_name }}</p>
                                                </div>
                                            </td>

                                            <td>
                                                <div class="tableBodyCtmTxt">
                                                    <p>{{ groupedContact.contact.last_name }}</p>
                                                </div>
                                            </td>

                                            <td>
                                                <div class="tableBodyCtmTxt">
                                                    <p>{{ groupedContact.contact.email }}</p>
                                                </div>
                                            </td>

                                            <td>
                                                <div class="tableBodyCtmTxt">
                                                    <p>{{ groupedContact.contact.mobile_no ? groupedContact.contact.mobile_no : '--' }}</p>
                                                </div>
                                            </td>

                                            <td>
                                                <div class="tableBodyCtmTxt">
                                                    <p>{{ groupedContact.contact.city ? groupedContact.contact.city.name : '--' }}</p>
                                                </div>
                                            </td>

                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr v-for="(groupedContact,index) in groupedContacts.contacts" :key="`groupedContact-${index}`">

                                            <td>
                                                <div class="tableBodyCtmTxt">
                                                    <p>{{ groupedContact.first_name + " " + groupedContact.last_name }}</p>
                                                </div>
                                            </td>
                                            
                                            <td>
                                                <div class="tableBodyCtmTxt">
                                                    <p>{{ groupedContact.email }}</p>
                                                </div>
                                            </td>

                                            <td>
                                                <div class="tableBodyCtmTxt">
                                                    <p>{{ groupedContact.mobile_no ? groupedContact.mobile_no : '--' }}</p>
                                                </div>
                                            </td>

                                            <td>
                                                <div class="tableBodyCtmTxt">
                                                    <p>{{ groupedContact.city ? groupedContact.city.name : '--' }}</p>
                                                </div>
                                            </td>

                                            <td>
                                                <div class="productOrderTableTxt">
                                                    <p class="Paid" v-if="groupedContact.status == 'sent'">Sent</p>
                                                    <p class="Cancelled" v-else>Droppped</p>
                                                </div>
                                            </td>

                                            <td>
                                                <div class="tableBodyCtmTxt">
                                                    <p>{{ groupedContact.description ? groupedContact.description : '--' }}</p>
                                                </div>
                                            </td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="modalCloseIcon">
                        <button @click="groupedContacts.isOpen = false">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                                <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <!-- grouped contact modal ends here -->
            
            <!-- email template selection modal starts here-->
            <div class="modalBg categoryResponsiveModal" :class="templateSelectionModal.isOpen ? 'modal-active' : ''">
                <div class="modalLayers" @click.prevent="closeTemplateSelectionModal()"></div>
                <div class="modalBody">
                    <div class="oderSlideBox productSideBox" style="position: relative; top: 15px; right: 0; width: 100%">
                        <div class="row" v-if="templateSelectionModal.isOpen && emailTemplatesModal.list.length == 0">
                            <div class="col-md-12">
                                <div class="notDataWrap">
                                    <p>
                                        No email templates available.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="emailTemplatesWrap">
                            <div class="emailTemplateItem"  v-for="(template,index) in emailTemplatesModal.list" :key="index">
                                <div class="templateContainer">
                                    <div class="emailTemplateWrap" v-html="template.template_html">

                                    </div>
                                    <div class="emailTemplateOverlay selection" :class="templateSelectionModal.template && templateSelectionModal.template.id == template.id ? 'visible' : ''" @click="selectEmailTemplateHandle(template)">
                                        <div class="selectionWrap" v-if="templateSelectionModal.template && templateSelectionModal.template.id == template.id">
                                            <svg version="1.1" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                                <title/>
                                                <desc/>
                                                <g fill="none" fill-rule="evenodd" id="Page-1" stroke="none" stroke-width="1"><g id="Artboard-Copy" transform="translate(-47.000000, -549.000000)">
                                                    <path d="M55,572 C55,580.838 62.164,588 71,588 C79.836,588 87,580.838 87,572 C87,563.164 79.836,556 71,556 C62.164,556 55,563.164 55,572 L55,572 Z M68.474,579.002 L65.506,576.031 L62.5,573.018 L65.468,570.047 L68.481,573.055 L76.532,565 L79.5,567.971 L68.474,579.002 Z" fill="#000000" id="check2"/>
                                                    <g id="slices" transform="translate(47.000000, 9.000000)"/></g>
                                                </g>
                                            </svg>
                                        </div>
                                        <div class="selectionWrap" v-else>
                                            <svg  version="1.1" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                                <title/>
                                                <desc/>
                                                <g fill="none" fill-rule="evenodd" id="Page-1" stroke="none" stroke-width="1">
                                                    <g id="Artboard-Copy" transform="translate(-407.000000, -489.000000)">
                                                        <path d="M424.125092,520.374775 L419.548425,515.869775 C418.815925,515.148942 418.815925,513.978108 419.548425,513.258942 L421.537592,511.301442 C422.266758,510.581442 423.454258,510.581442 424.183425,511.301442 L427.562592,514.626442 L438.814258,503.542275 C439.546758,502.821442 440.733425,502.821442 441.464258,503.542275 L443.450925,505.498942 C444.181758,506.219775 444.181758,507.386442 443.450925,508.108108 L428.879258,522.459775 C428.148425,523.180608 426.961758,523.180608 426.229258,522.459775 L424.244258,520.503942 L424.125092,520.374775 L424.125092,520.374775 Z" fill="#000000" id="check2"/>
                                                        <g id="slices" transform="translate(47.000000, 9.000000)"/>
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div class="emailTemplateNameWrap">
                                    <p>
                                        {{ template.template_name }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modalCloseIcon">
                        <button @click="closeTemplateSelectionModal()">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                                <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <!-- email template selection modal ends here-->


            <!-- recipients modal starts here (data table) -->
            <div class="modalBg" :class="emailRecipientsModal.isOpen ? 'modal-active' : ''">
                <div class="modalLayers" @click="emailRecipientsModal.isOpen = false"></div>
                <div class="modalBody">
                    <div class="oderSlideBox productSideBox" 
                        style="position: relative; top: 15px; right: 0; width: 100%; min-height: 400px; max-height: 400px;" 
                        v-if="emailRecipientGroupContact.isOpen"
                    >
                        <div id="London" class="tabcontent">
                            <div class="contaient-div horizontal-scroll-fix">
                                <div class="customer-detail customer-detail-product contacts groupedContactsHeader">
                                    <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" @click="emailRecipientGroupContact.isOpen = false">
                                        <title/>
                                        <g data-name="Layer 2" id="Layer_2">
                                            <path d="M31,16A15,15,0,1,1,16,1,15,15,0,0,1,31,16ZM3,16A13,13,0,1,0,16,3,13,13,0,0,0,3,16Z"/>
                                            <path d="M19.87,10.41,14.29,16l5.58,5.59a1,1,0,0,1,0,1.41h0a1,1,0,0,1-1.41,0L12.1,16.64a.91.91,0,0,1,0-1.28L18.46,9a1,1,0,0,1,1.41,0h0A1,1,0,0,1,19.87,10.41Z"/>
                                        </g>
                                    </svg>
                                    <h2>Contacts Included In "{{ emailRecipientGroupContact.group.name }}"</h2>
                                </div>
                            </div>
                            <div class="content-table">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>
                                                <div class="ctmCheckWrap">
                                                    <div class="ctmTableImgWrap">
                                                        <p data-toggle="tooltip" data-placement="top" title="Contact Full Name">
                                                            Full Name
                                                        </p>
                                                    </div>
                                                </div>
                                            </th>
                                            <th>
                                                <div class="ctmCheckWrap">
                                                    <div class="ctmTableImgWrap">
                                                        <p data-toggle="tooltip" data-placement="top" title="Contact Email">
                                                            Email
                                                        </p>
                                                    </div>
                                                </div>
                                            </th>
                                            <th>
                                                <div class="ctmCheckWrap">
                                                    <div class="ctmTableImgWrap">
                                                        <p data-toggle="tooltip" data-placement="top" title="Contact Phone">
                                                            Phone
                                                        </p>
                                                    </div>
                                                </div>
                                            </th>
                                            <th>
                                                <div class="ctmCheckWrap">
                                                    <div class="ctmTableImgWrap">
                                                        <p data-toggle="tooltip" data-placement="top" title="Contact City">
                                                            City
                                                        </p>
                                                    </div>
                                                </div>
                                            </th>
                                            <th>
                                                <div class="ctmCheckWrap">
                                                    <div class="ctmTableImgWrap">
                                                        <p data-toggle="tooltip" data-placement="top" title="Contact Status">
                                                            Status
                                                        </p>
                                                    </div>
                                                </div>
                                            </th>
                                            <th>
                                                <div class="ctmCheckWrap">
                                                    <div class="ctmTableImgWrap">
                                                        <p data-toggle="tooltip" data-placement="top" title="Contact Description">
                                                            Description
                                                        </p>
                                                    </div>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(groupedContact,index) in emailRecipientGroupContact.contacts" :key="`recipientGroupedContact-${index}`">

                                            <td>
                                                <div class="tableBodyCtmTxt">
                                                    <p>{{ groupedContact.first_name + " " + groupedContact.last_name }}</p>
                                                </div>
                                            </td>

                                            <td>
                                                <div class="tableBodyCtmTxt">
                                                    <p>{{ groupedContact.email }}</p>
                                                </div>
                                            </td>

                                            <td>
                                                <div class="tableBodyCtmTxt">
                                                    <p>{{ groupedContact.mobile_no ? groupedContact.mobile_no : '--' }}</p>
                                                </div>
                                            </td>

                                            <td>
                                                <div class="tableBodyCtmTxt">
                                                    <p>{{ groupedContact.city ? groupedContact.city.name : '--' }}</p>
                                                </div>
                                            </td>

                                            <td>
                                                <div class="productOrderTableTxt">
                                                    <p class="Paid" v-if="groupedContact.status == 'sent'">Sent</p>
                                                    <p class="Cancelled" v-else>Droppped</p>
                                                </div>
                                            </td>

                                            <td>
                                                <div class="tableBodyCtmTxt">
                                                    <p>{{ groupedContact.description ? groupedContact.description : '--' }}</p>
                                                </div>
                                            </td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="oderSlideBox productSideBox" style="position: relative; top: 15px; right: 0; width: 100%; min-height: 400px; max-height: 400px;" v-else>
                        <!-- contacts -->
                        <div id="London" class="tabcontent" v-if="emailRecipientsModal.contacts.length > 0">
                            <div class="contaient-div horizontal-scroll-fix">
                                <div class="customer-detail customer-detail-product contacts">
                                    <h2>Contacts</h2>
                                </div>
                            </div>
                            <div class="content-table">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>
                                                <div class="ctmCheckWrap">
                                                    <div class="ctmTableImgWrap">
                                                        <p data-toggle="tooltip" data-placement="top" title="Contact Full Name">
                                                            Full Name
                                                        </p>
                                                    </div>
                                                </div>
                                            </th>
                                            <th>
                                                <div class="ctmCheckWrap">
                                                    <div class="ctmTableImgWrap">
                                                        <p data-toggle="tooltip" data-placement="top" title="Contact Email">
                                                            Email
                                                        </p>
                                                    </div>
                                                </div>
                                            </th>
                                            <th>
                                                <div class="ctmCheckWrap">
                                                    <div class="ctmTableImgWrap">
                                                        <p data-toggle="tooltip" data-placement="top" title="Contact Phone">
                                                            Phone
                                                        </p>
                                                    </div>
                                                </div>
                                            </th>
                                            <th>
                                                <div class="ctmCheckWrap">
                                                    <div class="ctmTableImgWrap">
                                                        <p data-toggle="tooltip" data-placement="top" title="Contact City">
                                                            City
                                                        </p>
                                                    </div>
                                                </div>
                                            </th>
                                            <th>
                                                <div class="ctmCheckWrap">
                                                    <div class="ctmTableImgWrap">
                                                        <p data-toggle="tooltip" data-placement="top" title="Contact Status">
                                                            Status
                                                        </p>
                                                    </div>
                                                </div>
                                            </th>
                                            <th>
                                                <div class="ctmCheckWrap">
                                                    <div class="ctmTableImgWrap">
                                                        <p data-toggle="tooltip" data-placement="top" title="Contact Description">
                                                            Description
                                                        </p>
                                                    </div>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(groupedContact,index) in emailRecipientsModal.contacts" :key="`dataTableGroupedContact-${index}`">

                                            <td>
                                                <div class="tableBodyCtmTxt">
                                                    <p>{{ groupedContact.first_name + " " + groupedContact.last_name }}</p>
                                                </div>
                                            </td>

                                            <td>
                                                <div class="tableBodyCtmTxt">
                                                    <p>{{ groupedContact.email }}</p>
                                                </div>
                                            </td>

                                            <td>
                                                <div class="tableBodyCtmTxt">
                                                    <p>{{ groupedContact.mobile_no ? groupedContact.mobile_no : '--' }}</p>
                                                </div>
                                            </td>

                                            <td>
                                                <div class="tableBodyCtmTxt">
                                                    <p>{{ groupedContact.city ? groupedContact.city.name : '--' }}</p>
                                                </div>
                                            </td>

                                            <td>
                                                <div class="productOrderTableTxt">
                                                    <p class="Paid" v-if="groupedContact.status == 'sent'">Sent</p>
                                                    <p class="Cancelled" v-else>Droppped</p>
                                                </div>
                                            </td>

                                            <td>
                                                <div class="tableBodyCtmTxt">
                                                    <p>{{ groupedContact.description ? groupedContact.description : '--' }}</p>
                                                </div>
                                            </td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <!-- groups -->
                        <div id="London" class="tabcontent" v-if="emailRecipientsModal.groups.length > 0">
                            <div class="contaient-div horizontal-scroll-fix">
                                <div class="customer-detail customer-detail-product contacts">
                                    <h2>Groups</h2>
                                </div>
                            </div>
                            <div class="content-table">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>
                                                <div class="ctmCheckWrap">
                                                    <div class="ctmTableImgWrap">
                                                        <p data-toggle="tooltip" data-placement="top" title="Group Name">
                                                            Name
                                                        </p>
                                                    </div>
                                                </div>
                                            </th>
                                            <th>
                                                <div class="ctmCheckWrap">
                                                    <div class="ctmTableImgWrap">
                                                        <p data-toggle="tooltip" data-placement="top" title="Group Description">
                                                            Description
                                                        </p>
                                                    </div>
                                                </div>
                                            </th>
                                            <th>
                                                <div class="ctmCheckWrap">
                                                    <div class="ctmTableImgWrap">
                                                        <p data-toggle="tooltip" data-placement="top" title="Group Contacts">
                                                            Contacts
                                                        </p>
                                                    </div>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(listedGroup,index) in emailRecipientsModal.groups" :key="`listedGroup-${index}`">
                                            
                                            <td>
                                                <div class="tableBodyCtmTxt">
                                                    <p>{{ listedGroup.group.name }}</p>
                                                </div>
                                            </td>

                                            <td>
                                                <div class="tableBodyCtmTxt">
                                                    <p>{{ listedGroup.group.description ? listedGroup.group.description : "--" }}</p>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="ctmDataTableActionBtn">
                                                    <el-tooltip class="box-item" effect="dark" content="Grouped Contacts" placement="top">
                                                        <button class="delFunction view" @click.prevent="openEmailRecipientGroupContactsModal(listedGroup,index)" :disabled="tourIsActive" data-open="false">
                                                            <svg baseProfile="tiny" height="24px" id="Layer_1" version="1.2" viewBox="0 0 24 24" width="24px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                                                <g>
                                                                    <path d="M12,14c1.381,0,2.631-0.56,3.536-1.465C16.44,11.631,17,10.381,17,9s-0.56-2.631-1.464-3.535C14.631,4.56,13.381,4,12,4   S9.369,4.56,8.464,5.465C7.56,6.369,7,7.619,7,9s0.56,2.631,1.464,3.535C9.369,13.44,10.619,14,12,14z"/>
                                                                    <path d="M20,15c0.69,0,1.315-0.279,1.768-0.731c0.453-0.452,0.732-1.077,0.732-1.769c0-0.69-0.279-1.315-0.732-1.768   C21.315,10.279,20.69,10,20,10c-0.691,0-1.316,0.279-1.769,0.732C17.779,11.185,17.5,11.81,17.5,12.5   c0,0.691,0.279,1.316,0.731,1.769S19.309,15,20,15z"/>
                                                                    <path d="M20,15.59c-1.331,0-2.332,0.406-2.917,0.968C15.968,15.641,14.205,15,12,15c-2.266,0-3.995,0.648-5.092,1.564   C6.312,15.999,5.3,15.59,4,15.59c-2.188,0-3.5,1.09-3.5,2.182c0,0.545,1.312,1.092,3.5,1.092c0.604,0,1.146-0.051,1.623-0.133   c-0.01,0.091-0.04,0.18-0.04,0.27c0,1,2.406,2,6.417,2c3.762,0,6.417-1,6.417-2c0-0.085-0.011-0.17-0.02-0.255   c0.463,0.073,0.995,0.118,1.603,0.118c2.051,0,3.5-0.547,3.5-1.092C23.5,16.68,22.127,15.59,20,15.59z"/>
                                                                    <path d="M4,15c0.69,0,1.315-0.279,1.768-0.732C6.221,13.815,6.5,13.19,6.5,12.5c0-0.689-0.279-1.314-0.732-1.768   C5.315,10.28,4.69,10,4,10c-0.691,0-1.316,0.28-1.769,0.732C1.779,11.186,1.5,11.811,1.5,12.5c0,0.69,0.279,1.315,0.731,1.768   C2.684,14.721,3.309,15,4,15z"/>
                                                                </g>
                                                            </svg>
                                                        </button>
                                                    </el-tooltip>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="modalCloseIcon">
                        <button @click="emailRecipientsModal.isOpen = false">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                                <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <!-- recipients modal ends here (data table) -->



            <!-- all email templates modal starts here -->
            <div class="modalBg categoryResponsiveModal" :class="emailTemplatesModal.isOpen ? 'modal-active' : ''">
                <div class="modalLayers" @click.prevent="emailTemplatesModal.isOpen = false"></div>
                <div class="modalBody">
                    <div class="oderSlideBox productSideBox" style="position: relative; top: 15px; right: 0; width: 100%">
                        <div class="row" v-if="emailTemplatesModal.isOpen && emailTemplatesModal.list.length == 0">
                            <div class="col-md-6">
                                <div class="notDataWrap">
                                    <p>
                                        No email templates available.
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-6 mt-3">
                                <div class="assign-btn">
                                    <button type="submit" class="product-btn" @click="openPreviewEmailTemplateModal(null,'add')">
                                        Add Email Template
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-else>
                            <div class="col-md-12 mt-3">
                                <div class="assign-btn">
                                    <button type="submit" class="product-btn" @click="openPreviewEmailTemplateModal(null,'add')">
                                        Add Email Template
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="emailTemplatesWrap">
                            <div class="emailTemplateItem"  v-for="(template,index) in emailTemplatesModal.list" :key="index">
                                <div class="emailTemplateWrap" v-html="template.template_html">

                                </div>
                                <div class="emailTemplateNameWrap">
                                    <p>
                                        {{ template.template_name }}
                                    </p>
                                </div>
                                <div class="emailTemplateOverlay">
                                    <div class="btnWrap">
                                        <button @click="openPreviewEmailTemplateModal(template,'edit')">
                                            <svg class="feather feather-edit" fill="none" height="24" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"/>
                                                <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"/>
                                            </svg>
                                        </button>
                                        <loaderBtn v-if="isDeletingTemplate(template)"/>
                                        <button @click="removeEmailTemplateHandle(template)" v-else>
                                           <svg enable-background="new 0 0 32 32" id="Glyph" version="1.1" viewBox="0 0 32 32" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                                <path d="M27,6h-6V5c0-1.654-1.346-3-3-3h-4c-1.654,0-3,1.346-3,3v1H5C3.897,6,3,6.897,3,8v1c0,0.552,0.448,1,1,1h24  c0.552,0,1-0.448,1-1V8C29,6.897,28.103,6,27,6z M13,5c0-0.551,0.449-1,1-1h4c0.551,0,1,0.449,1,1v1h-6V5z" id="XMLID_246_"/>
                                                <path d="M6,12v15c0,1.654,1.346,3,3,3h14c1.654,0,3-1.346,3-3V12H6z M19.707,22.293  c0.391,0.391,0.391,1.023,0,1.414s-1.023,0.391-1.414,0L16,21.414l-2.293,2.293c-0.391,0.391-1.023,0.391-1.414,0  s-0.391-1.023,0-1.414L14.586,20l-2.293-2.293c-0.391-0.391-0.391-1.023,0-1.414s1.023-0.391,1.414,0L16,18.586l2.293-2.293  c0.391-0.391,1.023-0.391,1.414,0s0.391,1.023,0,1.414L17.414,20L19.707,22.293z" id="XMLID_249_"/>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modalCloseIcon">
                        <button @click="emailTemplatesModal.isOpen = false">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                                <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <!-- all email templates modal ends here -->


            <!-- add/update email template modal starts here -->
            <ValidationObserver ref="emailTemplateObserver">
                <div class="modalBg categoryResponsiveModal" :class="previewEmailTemplateModal.isOpen ? 'modal-active' : ''">
                    <div class="modalLayers" @click.prevent="previewEmailTemplateModal.isOpen = false"></div>
                    <div class="modalBody">
                        <div class="oderSlideBox productSideBox" style="position: relative; top: 15px; right: 0; width: 100%">
                            <div id="London" class="tabcontent">
                                <div class="contaient-div horizontal-scroll-fix contaient-div-b">
                                    <form class="customerDetailFormBox" @submit.prevent="validate('templatePreview')">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <ValidationProvider name="template name" rules="required|max:250" v-slot="{ errors }">
                                                    <div class="formContentBox">
                                                        <label for="templateName">Template Name <span>*</span></label>
                                                        <input 
                                                            type="text" 
                                                            id="templateName" 
                                                            name="templateName" 
                                                            class="form-control" 
                                                            placeholder="Template name" 
                                                            v-model="previewEmailTemplateModal.template_name" 
                                                        >
                                                        <span class="text-danger" >{{ errors[0] }}</span>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="formContentBox">
                                                    <label >Template Design <span>*</span></label>
                                                    <EmailEditor
                                                        ref="previewEmailTemplateEditor"
                                                        @load="previewEmailEditorLoaded"
                                                        @ready="previewEmailEditorReady"
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="formCustomerBtn">
                                                    <loaderBtn v-if="previewEmailTemplateModal.pending"/>
                                                    <button v-else type="submit">Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="modalCloseIcon">
                            <button @click="previewEmailTemplateModal.isOpen = false">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                                    <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </ValidationObserver>
            <!-- add/update email template modal ends here -->

        </div>

        <!--vue tour-->
        <v-tour name="emailsTour" :steps="tourSteps" :options="tourOptions" :callbacks="tourCallbacks"></v-tour>

    </div>
</template>

<script>
import subHeader from "@/components/subHeader.vue";
import moduleStats from "@/components/moduleStats.vue";
import tutorialsAction from "@/components/tutorialsAction.vue";
import loaderBtn from "@/components/loaderBtn.vue";
import _ from 'lodash';
import { mapGetters } from 'vuex';
import {MessageBox, Message} from 'element-ui'
import Multiselect from "vue-multiselect";
import { EmailEditor } from 'vue-email-editor';

export default {
    name:'Emails',
    metaInfo() {
        return {
            title: 'CRM | Emails | YeetCommerce',
        };
    },
    data() {
        return {
            base_image_url: process.env.VUE_APP_STORE_IMAGE_BASE_URL,
            //search start
            searchedItem: '',
            searchLoader: false,
            //search end

            checkedItems: [],
            deletingItems: [],
            deleteLoader: false,
            timer: undefined,
            isMasterSearched: false,

            //emails start
            emailsLoading: false,
            emailsList: [],
            isEmailSaved: false,
            emailIsSaving: false,
            previewForm:{
                id: '',
                subject: '',
                type: '',
                template_design: '',
                template_html: '',
                contacts: [],
                groups: [],
                isOpen: false,
                pending: false,
                isTouched: false,
            },
            tempEmail:{
                id: '',
                subject: '',
                type: '',
                template_design: '',
                template_html: '',
                contacts: [],
                groups: [],
                isOpen: false,
                pending: false,
                isTouched: false,
            },
            emailTypes:[
                'welcome',
                'announcement',
                'promotional',
                'newsletter',
                'confirmation',
                'support',
            ],
            //emails end

            //email recipients start
            emailRecipientsModal:{
                email: {},
                groups: [],
                contacts: [],
                isOpen: false
            },
            emailRecipientGroupContact:{
                group: {},
                contacts: [],
                isOpen: false,
            },
            //email recipients end

            //groups start
            groupsModal:{
                list: [],
                selectedGroups: [],
                isOpen: false,
            },
            groupedContacts:{
                group: {},
                contacts: [],
                isOpen: false,
            },
            searchedGroup: '',
            groupsSearchLoader: false,
            groupsPagination:{
                current_page: 0,
                total: 0,
                previous_page: 0,
                last_page: 0,
            },
            //groups end

            //contacts start
            contactsModal:{
                list:[],
                selectedContacts:[],
                isOpen:false,
            },
            searchedContact: '',
            contactsSearchLoader: false,
            contactsPagination:{
                current_page: 0,
                total: 0,
                previous_page: 0,
                last_page: 0,
            },
            //contacts end

            //template selection start
            templateSelectionModal:{
                template:{},
                isOpen:false,
            },
            //template selection end


            //email templates start
            emailTemplatesModal:{
                list:[],
                isOpen:false,
            },
            previewEmailTemplateModal:{
                template_id:'',
                template_name:'',
                template_design:{},
                isOpen:false,
                isEdit:false,
                pending:false,
            },
            defaultTemplateDesign:{
                "counters":{"u_column":1,"u_row":1},
                "body":{
                    "id":"7NEZhNhBXE",
                    "rows":[
                        {
                            "id":"JDYDOYRerD",
                            "cells":[1],
                            "columns":[
                                {   "id":"uPEmZcU4dg",
                                    "contents":[],
                                    "values":{
                                        "backgroundColor":"",
                                        "padding":"0px",
                                        "border":{},
                                        "borderRadius":"0px",
                                        "_meta":{
                                            "htmlID":"u_column_1",
                                            "htmlClassNames":"u_column"
                                        }
                                    }
                                }
                            ],
                            "values":{
                                "displayCondition":null,
                                "columns":false,
                                "backgroundColor":"",
                                "columnsBackgroundColor":"",
                                "backgroundImage":{
                                    "url":"",
                                    "fullWidth":true,
                                    "repeat":"no-repeat",
                                    "size":"custom",
                                    "position":"center",
                                    "customPosition":["50%","50%"]
                                },
                                "padding":"0px",
                                "anchor":"",
                                "hideDesktop":false,
                                "_meta":{
                                    "htmlID":"u_row_1",
                                    "htmlClassNames":"u_row"
                                },
                                "selectable":true,
                                "draggable":true,
                                "duplicatable":true,
                                "deletable":true,
                                "hideable":true
                            }
                        }
                    ],
                    "headers":[],
                    "footers":[],
                    "values":{
                        "popupPosition":"center",
                        "popupWidth":"600px",
                        "popupHeight":"auto",
                        "borderRadius":"10px",
                        "contentAlign":"center",
                        "contentVerticalAlign":"center",
                        "contentWidth":"500px",
                        "fontFamily":{
                            "label":"Arial",
                            "value":"arial,helvetica,sans-serif"
                        },
                        "textColor":"#000000",
                        "popupBackgroundColor":"#FFFFFF",
                        "popupBackgroundImage":{
                            "url":"",
                            "fullWidth":true,
                            "repeat":"no-repeat",
                            "size":"cover",
                            "position":"center"
                        },
                        "popupOverlay_backgroundColor":"rgba(0, 0, 0, 0.1)",
                        "popupCloseButton_position":"top-right",
                        "popupCloseButton_backgroundColor":"#DDDDDD",
                        "popupCloseButton_iconColor":"#000000",
                        "popupCloseButton_borderRadius":"0px",
                        "popupCloseButton_margin":"0px",
                        "popupCloseButton_action":{
                            "name":"close_popup",
                            "attrs":{
                                "onClick":"document.querySelector('.u-popup-container').style.display = 'none';"
                            }
                        },
                        "backgroundColor":"#e7e7e7",
                        "backgroundImage":{
                            "url":"",
                            "fullWidth":true,
                            "repeat":"no-repeat",
                            "size":"custom",
                            "position":"center"
                        },
                        "preheaderText":"",
                        "linkStyle":{
                            "body":true,
                            "linkColor":"#0000ee",
                            "linkHoverColor":"#0000ee",
                            "linkUnderline":true,
                            "linkHoverUnderline":true
                        },"_meta":{
                            "htmlID":"u_body",
                            "htmlClassNames":"u_body"
                        }
                    }
                },
                "schemaVersion":16
            },
            deletingTemplates:[],
            //email templates end

            //mergeTags
            mergeTags:{
                firstName:{
                    name: "First Name",
                    value: "{{first_name}}",
                    sample: "{{first_name}}"
                },
                lastName:{
                    name: "Last Name",
                    value: "{{last_name}}",
                    sample: "{{last_name}}"
                },
                email:{
                    name: "Email",
                    value: "{{email}}",
                    sample: "{{email}}"
                },
                mobile_no:{
                    name: "Mobile No.",
                    value: "{{mobile_no}}",
                    sample: "{{mobile_no}}"
                },
                jobTitle:{
                    name: "Job Title",
                    value: "{{job_title}}",
                    sample: "{{job_title}}"
                },
                city:{
                    name: "City",
                    value: "{{city}}",
                    sample: "{{city}}"
                },
                address:{
                    name: "Address",
                    value: "{{address}}",
                    sample: "{{address}}"
                },
                unSubscribe:{
                    name:"Unsubscribe Link",
                    value:"{{unsubscribe_link}}",
                    sample:"{{unsubscribe_link}}",
                }
            },

            //vue-tour
            tourOptions: {
                useKeyboardNavigation: false,
                labels: {
                    buttonSkip: 'Skip Tour',
                    buttonPrevious: 'Previous',
                    buttonNext: 'Next',
                    buttonStop: 'Finish Tour'
                }
            },
            tourCallbacks: {
                onPreviousStep: this.handlePreviousStep,
                onNextStep: this.handleNextStep,
                // onStart: this.handleTourStart,
                onStop: this.handleTourStop,
                onFinish: this.handleTourFinish,
                onSkip: this.handleTourSkip
            },
            tourSteps:[
                //header steps
                {
                    target: '#tour-start-btn',
                    content: `<strong>Start Tour</strong><br>Provides insights into the available functionalities within a specific menu, helping you understand what actions you can perform.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#master-search',
                    content: `<strong>Master Search</strong><br>Enables you to perform searches from all over the dashboard.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    },
                },
                {
                    target: '#add-new-dropDown',
                    content: `<strong>Add New Record</strong><br>Enables you to add new orders, products and categories on the go.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#help-link',
                    content: `<strong>Help</strong><br>If you require any assistance, feel free to reach out to us.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#user-options-dropdown',
                    content: `<strong>User Information</strong><br>Presents essential details about you along with a selection of available options.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                //search bar step
                {
                    target: '#email-search',
                    content: `<strong>Search Email</strong><br>It enables you to search specific emails.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'bottom'
                    }
                },
                //form steps
                {
                    target: '#preview-record',
                    content: `<strong>Emails Table</strong><br>You can preview the added emails by clicking on each individual record present in the table.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'top'
                    }
                },
                {
                    target: '#add-new-record',
                    content: `Add an <strong>Email</strong><br>By clicking this button, you can add a new email and open a form to input and save the details.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#email-details-preview',
                    content: `<strong>Email Details Form</strong><br>This form can be used to add details about the email and save them.`,
                    params: {
                        highlight: false,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#subject-field',
                    content: `<strong>Email's Subject</strong><br>Subject of the email can be added using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#email-type-field',
                    content: `<strong>Email Type</strong><br>Type of the email can be selected using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#template-selection-btn',
                    content: `<strong>Email Template Selection</strong><br>Template for the email can be selection by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
                {
                    target: '#email-template-box',
                    content: `<strong>Email Template</strong><br>Template for the email can be manipulated using this section.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
                {
                    target: '#contacts-table',
                    content: `<strong>Contacts Table</strong><br>Contacts added as recipients to the email can be viewed in this section.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#contacts-add-btn',
                    content: `<strong>Add Contacts</strong><br>Contacts can be added to the email as recipients by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
                {
                    target: '#groups-table',
                    content: `<strong>Groups Table</strong><br>Groups added as recipients to the email can be viewed in this section.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#groups-add-btn',
                    content: `<strong>Add Groups</strong><br>Groups can be added to the email as recipients by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
                {
                    target: '#header-subject-field',
                    content: `<strong>Emails's Subject</strong><br>Subject of the email can also be added using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#expand-form',
                    content: `<strong>Expand Form</strong><br>Form can be expanded or collapsed by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
                {
                    target: '#closeSide',
                    content: `<strong>Close Form</strong><br>Form can be closed using this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
                {
                    target: '#send-email-btn',
                    content: `<strong>Send Email</strong><br>Email details can be saved and sent to the recipients by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
            ],
            tourIsActive:false,

        }
    },
    components:{
        subHeader,
        tutorialsAction,
        moduleStats,
        loaderBtn,
        Multiselect,
        EmailEditor
    },
    computed:{
        ...mapGetters({
            settings: 'settings_module/settings',
            storedEmailTemplates: 'email_templates_module/templates',
            allStoredGroups: 'groups_module/allGroups',
            //emails
            storedEmails: 'emails_module/emails',
            emailsCurrentPage: 'emails_module/currentPage',
            emailsLastPage: 'emails_module/lastPage',
            emailsSearchKeyword:'emails_module/keyword',
            isPending: 'emails_module/isPending',
        }),
    },
    watch:{
        'storedEmailTemplates':{
            handler:function(value){

                this.emailTemplatesModal.list = _.cloneDeep(value)

            },deep:true
        },
        'allStoredGroups':{
            handler:function(value){

                this.groupsModal.list = _.cloneDeep(value)

            },deep:true
        },
        'storedEmails':{
            handler:function(value){

                this.emailsLoading = false;
                this.searchLoader = false;

                this.emailsList = _.cloneDeep(value)

                if(this.isMasterSearched){

                    this.previewSearchedEmail(this.emailsList[0],0)

                    this.isMasterSearched = false
                }

            },deep:true
        },
        'previewForm':{
            handler:function(value){

                if((value.id == -1) && (value.subject || value.type || value.contacts.length > 0 || value.groups.length > 0)){

                    value.isTouched = true

                }else{

                    value.isTouched = false

                }
            },
            deep:true
        },
    },
    methods:{
    
        handleScrollEvent(event){
            
            let element = this.$refs.emailsTable;
            
            let maxScrollTop = element.scrollHeight - element.clientHeight;

            if(element.scrollTop >= maxScrollTop - 100){

                if(this.isPending == false && this.emailsCurrentPage != this.emailsLastPage){

                    this.$store.dispatch('emails_module/fetchStoreEmailsRequest',{size:15 , page: this.emailsCurrentPage + 1, loadData:'more'})

                }

            }

        },

        //validate add/update form start
        async validate(form){
            
            if(form == 'preview'){

                this.$refs.emailObserver.validate().then(success => {

                    if(!success){

                        const errors = Object.entries(this.$refs.emailObserver.errors)
                        .map(([key, value]) => ({ key, value })).filter(error => error["value"].length);

                        this.$refs.emailObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth",  block: "center" });

                    }else{

                        if((this.previewForm.contacts.length == 0) && 
                        (this.previewForm.groups.length == 0)){

                            this.$notify({
                                type: 'info',
                                title: 'Recipients Missing',
                                message: "To send an email, you must provide at least one recipient.",
                            });

                        }else{

                            let tempTemplate = {
                                design: null,
                                html: null,
                            }

                            this.$refs.emailPreviewTemplateEditor.editor.exportHtml((data) =>{
                    
                                tempTemplate.design = data.design
                                tempTemplate.html = data.html

                                this.sendEmailHandle(tempTemplate);

                            },{
                                minify: true,
                            })

                        }

                    }

                });

            }else if(form == 'templatePreview'){

                this.$refs.emailTemplateObserver.validate().then(success => {

                    if(!success){

                        const errors = Object.entries(this.$refs.emailTemplateObserver.errors)
                        .map(([key, value]) => ({ key, value })).filter(error => error["value"].length);

                        this.$refs.emailTemplateObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });

                    }else{

                        let tempTemplate = {
                            design: null,
                            html: null,
                        }

                        this.$refs.previewEmailTemplateEditor.editor.exportHtml((data) =>{
                
                            tempTemplate.design = data.design
                            tempTemplate.html = data.html

                            this.saveEmailTemplateHandle(tempTemplate);

                        },{
                            minify: true,
                        })

                    }

                });

            }
        
        },
        //validate add/update form end

        //email templates methods start
        openAllEmailTemplatesModal(){

            this.emailTemplatesModal.isOpen = true

        },
        openPreviewEmailTemplateModal(template,form){

            if(form == 'add'){

                this.previewEmailTemplateModal = {
                    template_id:'',
                    template_name:'',
                    template_design:{},
                    isOpen:true,
                    isEdit:false,
                    pending:false,
                }

                this.$refs.emailTemplateObserver.reset();

                if(this.$refs.previewEmailTemplateEditor){

                    this.$refs.previewEmailTemplateEditor.editor.loadDesign(this.defaultTemplateDesign);
                
                    this.$refs.previewEmailTemplateEditor.editor.setMergeTags(this.mergeTags);

                    this.$refs.previewEmailTemplateEditor.editor.registerCallback('image',function(file,done){
                
                        let formData = new FormData();

                        formData.append('image',file.attachments[0])

                        formData.append('store_id',this.settings.store_id);

                        let baseUrl = process.env.VUE_APP_BASE_URL

                        fetch(baseUrl + '/crm/email/image/upload',{
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json'
                            },
                            body: formData
                        }).then((response)=>{

                            if(response.status >= 200 && response.status < 300){

                                return response

                            }else{

                                var error = new Error(response.statusText)
                                error.response = response
                                throw error

                            }

                        }).then(response =>{

                            return response.json()
                            
                        }).then(data=>{

                            let spacesEndpoint = process.env.VUE_APP_STORE_IMAGE_BASE_URL

                            this.$store.commit('settings_module/update_remaining_space',data.remaining_space.usage_space);

                            done({ progress: 100, url: spacesEndpoint + this.settings.store_id + '/email-template/' + data.image })

                        })

                    }.bind(this));

                }

            }else if (form == 'edit'){

                this.previewEmailTemplateModal = {
                    template_id:'',
                    template_name:'',
                    template_design:{},
                    isOpen:false,
                    isEdit:false,
                    pending:false,
                }

                this.$refs.emailTemplateObserver.reset();

                this.previewEmailTemplateModal = {
                    template_id: template.id,
                    template_name: template.template_name,
                    template_design: template.template_design,
                    isOpen:true,
                    isEdit:true,
                    pending:false,
                }
                
                if(this.$refs.previewEmailTemplateEditor){

                    this.$refs.previewEmailTemplateEditor.editor.loadDesign(JSON.parse(this.previewEmailTemplateModal.template_design));

                    this.$refs.previewEmailTemplateEditor.editor.setMergeTags(this.mergeTags);

                    this.$refs.previewEmailTemplateEditor.editor.registerCallback('image',function(file,done){
                
                        let formData = new FormData();

                        formData.append('image',file.attachments[0])

                        formData.append('store_id',this.settings.store_id);

                        let baseUrl = process.env.VUE_APP_BASE_URL

                        fetch(baseUrl + '/crm/email/image/upload',{
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json'
                            },
                            body: formData
                        }).then((response)=>{

                            if(response.status >= 200 && response.status < 300){

                                return response

                            }else{

                                var error = new Error(response.statusText)
                                error.response = response
                                throw error

                            }

                        }).then(response =>{

                            return response.json()
                            
                        }).then(data=>{

                            let spacesEndpoint = process.env.VUE_APP_STORE_IMAGE_BASE_URL

                            this.$store.commit('settings_module/update_remaining_space',data.remaining_space.usage_space);

                            done({ progress: 100, url: spacesEndpoint + this.settings.store_id + '/email-template/' + data.image })

                        })

                    }.bind(this));

                }

            }

        },
        previewEmailEditorLoaded(){

            if(this.previewEmailTemplateModal.isEdit){

                this.$refs.previewEmailTemplateEditor.editor.loadDesign(JSON.parse(this.previewEmailTemplateModal.template_design));

                this.$refs.previewEmailTemplateEditor.editor.setMergeTags(this.mergeTags);

                this.$refs.previewEmailTemplateEditor.editor.registerCallback('image',function(file,done){
                
                    let formData = new FormData();

                    formData.append('image',file.attachments[0])

                    formData.append('store_id',this.settings.store_id);

                    let baseUrl = process.env.VUE_APP_BASE_URL

                    fetch(baseUrl + '/crm/email/image/upload',{
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json'
                        },
                        body: formData
                    }).then((response)=>{

                        if(response.status >= 200 && response.status < 300){

                            return response

                        }else{

                            var error = new Error(response.statusText)
                            error.response = response
                            throw error

                        }

                    }).then(response =>{

                        return response.json()
                        
                    }).then(data=>{

                        let spacesEndpoint = process.env.VUE_APP_STORE_IMAGE_BASE_URL

                        this.$store.commit('settings_module/update_remaining_space',data.remaining_space.usage_space);

                        done({ progress: 100, url: spacesEndpoint + this.settings.store_id + '/email-template/' + data.image })

                    })

                }.bind(this));

            }else{

                this.$refs.previewEmailTemplateEditor.editor.loadDesign(this.defaultTemplateDesign);

                this.$refs.previewEmailTemplateEditor.editor.setMergeTags(this.mergeTags);

                this.$refs.previewEmailTemplateEditor.editor.registerCallback('image',function(file,done){
                
                    let formData = new FormData();

                    formData.append('image',file.attachments[0])

                    formData.append('store_id',this.settings.store_id);

                    let baseUrl = process.env.VUE_APP_BASE_URL

                    fetch(baseUrl + '/crm/email/image/upload',{
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json'
                        },
                        body: formData
                    }).then((response)=>{

                        if(response.status >= 200 && response.status < 300){

                            return response

                        }else{

                            var error = new Error(response.statusText)
                            error.response = response
                            throw error

                        }


                    }).then(response =>{

                        return response.json()
                        
                    }).then(data=>{

                        let spacesEndpoint = process.env.VUE_APP_STORE_IMAGE_BASE_URL

                        this.$store.commit('settings_module/update_remaining_space',data.remaining_space.usage_space);

                        done({ progress: 100, url: spacesEndpoint + this.settings.store_id + '/email-template/' + data.image })

                    })

                }.bind(this));

            }

        },
        previewEmailEditorReady(){
        },
        //email templates methods end

        //save email template start
        async saveEmailTemplateHandle(templateData){

            if(this.previewEmailTemplateModal.isEdit){

                let formData = new FormData();

                formData.append('template_id',this.previewEmailTemplateModal.template_id)
                formData.append('template_name',this.previewEmailTemplateModal.template_name)
                formData.append('template_design',JSON.stringify(templateData.design));
                formData.append('template_html',templateData.html);

                this.previewEmailTemplateModal.pending = true
                try{

                    let res = await this.$axios.post('/crm/email/template/update',formData);
                    if(res.data.status_code == "1472"){

                        this.$notify({
                            type: 'success',
                            title: 'Success',
                            message: res.data.message,
                        });

                        let index = this.emailTemplatesModal.list.findIndex(template => template.id == res.data.template.id);

                        if(index != -1){

                            this.emailTemplatesModal.list[index] = res.data.template;

                        }

                        this.$store.commit('email_templates_module/update_email_template',res.data.template);

                        this.previewEmailTemplateModal.pending = false

                        this.previewEmailTemplateModal.isOpen = false

                    }

                }catch(error){

                    this.previewEmailTemplateModal.pending = false

                    if(error.response){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }else{
                        
                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.message,
                        });

                    }

                }

            }else{

                let formData = new FormData();

                formData.append('template_name',this.previewEmailTemplateModal.template_name)
                formData.append('template_design',JSON.stringify(templateData.design));
                formData.append('template_html',templateData.html);

                this.previewEmailTemplateModal.pending = true
                try{

                    let res = await this.$axios.post('/crm/email/template/add',formData);
                    if(res.data.status_code == "1471"){

                        this.$notify({
                            type: 'success',
                            title: 'Success',
                            message: res.data.message,
                        });

                        this.$store.commit('email_templates_module/add_email_template',res.data.template);

                        this.previewEmailTemplateModal.pending = false

                        this.previewEmailTemplateModal.isOpen = false

                    }

                }catch(error){

                    this.previewEmailTemplateModal.pending = false

                    if(error.response){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }else{
                        
                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.message,
                        });

                    }

                }

            }

        },
        async removeEmailTemplateHandle(listedTemplate){

            MessageBox.confirm(
                'Are you sure you want to delete the email template?',
                'Warning',
                {
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'Cancel',
                    type: 'warning',
                }
            ).then(async ()=>{

                this.deletingTemplates.push(listedTemplate.id)

                try{
                    let res = await this.$axios.delete(`/crm/email/template/remove/${listedTemplate.id}`);
                    if(res.data.status_code == "1473"){

                        this.$notify({
                            type: 'success',
                            title: 'Success',
                            message: res.data.message,
                        });

                        this.$store.commit('email_templates_module/delete_email_template',listedTemplate);

                        this.deletingTemplates = [];

                    }
                    
                }catch(error){

                    this.deletingTemplates = [];

                    if(error.response){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.message,
                        });

                    }

                }

            }).catch(()=>{})

        },
        isDeletingTemplate(listedTemplate){

            let find = this.deletingTemplates.find(x=>x == listedTemplate.id);

            if(find){

                return true;

            }else{

                return false;

            }

        },
        //save email template end

        //preview form open/close & reset start
        previewEmail(listedEmail,i,field,e){

            if(field == 'input'){

                if((e.target.tagName == "TD" || e.target.dataset.open == "true") && !this.tourIsActive){
                    
                    if(listedEmail.id != -1){

                        if(this.previewForm.id == -1){

                            MessageBox.confirm(
                                "Are you sure? unsaved changes will be discarded", 
                                "Warning", 
                                {
                                    confirmButtonText: "Yes",
                                    cancelButtonText: "Cancel",
                                    type: "warning",
                                }
                            ).then(()=>{

                                let index = this.emailsList.findIndex((email) => email.id == -1);
                                
                                if(index != -1){

                                    this.emailsList.splice(index, 1);

                                }

                                if(!this.isEmailSaved && (this.tempEmail.id != listedEmail.id)){
                                
                                    let tempIndex = this.emailsList.findIndex(x=>x.id == this.tempEmail.id);

                                    if(tempIndex != -1){

                                        this.emailsList[tempIndex] = _.cloneDeep(this.storedEmails[tempIndex])

                                    }

                                }

                                if(this.tempEmail.id != '' && this.tempEmail.id == listedEmail.id){
                                
                                    let tempIndex = this.emailsList.findIndex(x=>x.id == this.tempEmail.id);

                                    this.emailsList[tempIndex] = _.cloneDeep(this.storedEmails[tempIndex])

                                    let emailContacts = [];
                                    let emailGroups = [];

                                    this.emailsList[tempIndex].recipients.forEach((recipient)=>{

                                        if(recipient.group && recipient.contact){

                                            let foundIndex = emailGroups.findIndex(emailGroup => emailGroup.group.id == recipient.group.id);

                                            if(foundIndex == -1){

                                                let tempContact = recipient.contact

                                                tempContact.status = recipient.status

                                                tempContact.description = recipient.description

                                                let tempGroup = {
                                                    group:recipient.group,
                                                    contacts: [tempContact]
                                                }

                                                emailGroups.push(tempGroup);

                                            }else{

                                                let tempContact = recipient.contact

                                                tempContact.status == recipient.status

                                                tempContact.description = recipient.description

                                                emailGroups[foundIndex].contacts.push(tempContact)

                                            }

                                        }else if(!recipient.group && recipient.contact){

                                            recipient.contact.status = recipient.status

                                            recipient.contact.description = recipient.description

                                            emailContacts.push(recipient.contact);

                                        }

                                    });

                                    this.previewForm.id = this.emailsList[tempIndex].id
                                    this.previewForm.subject = this.emailsList[tempIndex].subject
                                    this.previewForm.type = this.emailsList[tempIndex].email_type
                                    this.previewForm.template_design = this.emailsList[tempIndex].template_design
                                    this.previewForm.template_html = this.emailsList[tempIndex].template_html
                                    this.previewForm.contacts = emailContacts
                                    this.previewForm.groups = emailGroups
                                    this.previewEmail.isOpen = true

                                    this.checkedItems = []

                                    if(this.$refs.emailPreviewTemplateEditor){

                                        this.$refs.emailPreviewTemplateEditor.editor.loadDesign(JSON.parse(this.emailsList[tempIndex].template_design));

                                        this.$refs.emailPreviewTemplateEditor.editor.setMergeTags(this.mergeTags);

                                        this.$refs.emailPreviewTemplateEditor.editor.registerCallback('image',function(file,done){

                                            let formData = new FormData();

                                            formData.append('image',file.attachments[0])

                                            formData.append('store_id',this.settings.store_id);

                                            let baseUrl = process.env.VUE_APP_BASE_URL

                                            fetch(baseUrl + '/crm/email/image/upload',{
                                                method: 'POST',
                                                headers: {
                                                    'Accept': 'application/json'
                                                },
                                                body: formData
                                            }).then((response)=>{

                                                if(response.status >= 200 && response.status < 300){

                                                    return response;

                                                }else{

                                                    var error = new Error(response.statusText);

                                                    error.response = response;

                                                    throw error;

                                                }

                                            }).then(response =>{

                                                return response.json();
                                                
                                            }).then(data=>{

                                                let spacesEndpoint = process.env.VUE_APP_STORE_IMAGE_BASE_URL;

                                                this.$store.commit('settings_module/update_remaining_space',data.remaining_space.usage_space);

                                                done({ progress: 100, url: spacesEndpoint + this.settings.store_id + '/email-template/' + data.image });

                                            });

                                        }.bind(this));

                                    }

                                    this.emailIsSaving = false;

                                    this.tempEmail = _.cloneDeep(this.emailsList[tempIndex]);
                                
                                }else{

                                    let emailContacts = [];
                                    let emailGroups = [];

                                    listedEmail.recipients.forEach((recipient)=>{

                                        if(recipient.group && recipient.contact){

                                            let foundIndex = emailGroups.findIndex(emailGroup => emailGroup.group.id == recipient.group.id);

                                            if(foundIndex == -1){

                                                let tempContact = recipient.contact

                                                tempContact.status = recipient.status

                                                tempContact.description = recipient.description

                                                let tempGroup = {
                                                    group:recipient.group,
                                                    contacts: [tempContact]
                                                }

                                                emailGroups.push(tempGroup);

                                            }else{

                                                let tempContact = recipient.contact

                                                tempContact.status == recipient.status

                                                tempContact.description = recipient.description

                                                emailGroups[foundIndex].contacts.push(tempContact)

                                            }

                                        }else if(!recipient.group && recipient.contact){

                                            recipient.contact.status = recipient.status

                                            recipient.contact.description = recipient.description

                                            emailContacts.push(recipient.contact);

                                        }

                                    });

                                    this.previewForm.id = listedEmail.id
                                    this.previewForm.subject = listedEmail.subject
                                    this.previewForm.type = listedEmail.email_type
                                    this.previewForm.template_design = listedEmail.template_design
                                    this.previewForm.template_html = listedEmail.template_html
                                    this.previewForm.contacts = emailContacts
                                    this.previewForm.groups = emailGroups
                                    this.previewEmail.isOpen = true

                                    this.checkedItems = []

                                    if(this.$refs.emailPreviewTemplateEditor){

                                        this.$refs.emailPreviewTemplateEditor.editor.loadDesign(JSON.parse(listedEmail.template_design));

                                        this.$refs.emailPreviewTemplateEditor.editor.setMergeTags(this.mergeTags);

                                        this.$refs.emailPreviewTemplateEditor.editor.registerCallback('image',function(file,done){

                                            let formData = new FormData();

                                            formData.append('image',file.attachments[0])

                                            formData.append('store_id',this.settings.store_id);

                                            let baseUrl = process.env.VUE_APP_BASE_URL

                                            fetch(baseUrl + '/crm/email/image/upload',{
                                                method: 'POST',
                                                headers: {
                                                    'Accept': 'application/json'
                                                },
                                                body: formData
                                            }).then((response)=>{

                                                if(response.status >= 200 && response.status < 300){

                                                    return response;

                                                }else{

                                                    var error = new Error(response.statusText);

                                                    error.response = response;

                                                    throw error;

                                                }


                                            }).then(response =>{

                                                return response.json();
                                                
                                            }).then(data=>{

                                                let spacesEndpoint = process.env.VUE_APP_STORE_IMAGE_BASE_URL;

                                                this.$store.commit('settings_module/update_remaining_space',data.remaining_space.usage_space);

                                                done({ progress: 100, url: spacesEndpoint + this.settings.store_id + '/email-template/' + data.image });

                                            });

                                        }.bind(this));

                                    }

                                    this.emailIsSaving = false;

                                    this.tempEmail = _.cloneDeep(listedEmail);

                                }


                            }).catch(()=>{})

                        }else{

                            let index = this.emailsList.findIndex((email) => email.id == -1);
                                
                            if(index != -1){

                                this.emailsList.splice(index, 1);

                            }

                            if(!this.isEmailSaved && (this.tempEmail.id != listedEmail.id)){

                                let tempIndex = this.emailsList.findIndex(x=>x.id == this.tempEmail.id);

                                if(tempIndex != -1){

                                    this.emailsList[tempIndex] = _.cloneDeep(this.storedEmails[tempIndex])

                                }

                            }

                            if(this.tempEmail.id != '' && this.tempEmail.id == listedEmail.id){
                                
                                let tempIndex = this.emailsList.findIndex(x=>x.id == this.tempEmail.id);

                                this.emailsList[tempIndex] = _.cloneDeep(this.storedEmails[tempIndex])

                                let emailContacts = [];
                                let emailGroups = [];

                                this.emailsList[tempIndex].recipients.forEach((recipient)=>{

                                    if(recipient.group && recipient.contact){

                                        let foundIndex = emailGroups.findIndex(emailGroup => emailGroup.group.id == recipient.group.id);

                                        if(foundIndex == -1){

                                            let tempContact = recipient.contact

                                            tempContact.status = recipient.status

                                            tempContact.description = recipient.description

                                            let tempGroup = {
                                                group:recipient.group,
                                                contacts: [tempContact]
                                            }

                                            emailGroups.push(tempGroup);

                                        }else{

                                            let tempContact = recipient.contact

                                            tempContact.status == recipient.status

                                            tempContact.description = recipient.description

                                            emailGroups[foundIndex].contacts.push(tempContact)

                                        }

                                    }else if(!recipient.group && recipient.contact){

                                        recipient.contact.status = recipient.status

                                        recipient.contact.description = recipient.description

                                        emailContacts.push(recipient.contact);

                                    }

                                });

                                this.previewForm.id = this.emailsList[tempIndex].id
                                this.previewForm.subject = this.emailsList[tempIndex].subject
                                this.previewForm.type = this.emailsList[tempIndex].email_type
                                this.previewForm.template_design = this.emailsList[tempIndex].template_design
                                this.previewForm.template_html = this.emailsList[tempIndex].template_html
                                this.previewForm.contacts = emailContacts
                                this.previewForm.groups = emailGroups
                                this.previewEmail.isOpen = true

                                this.checkedItems = []

                                if(this.$refs.emailPreviewTemplateEditor){

                                    this.$refs.emailPreviewTemplateEditor.editor.loadDesign(JSON.parse(this.emailsList[tempIndex].template_design));

                                    this.$refs.emailPreviewTemplateEditor.editor.setMergeTags(this.mergeTags);

                                    this.$refs.emailPreviewTemplateEditor.editor.registerCallback('image',function(file,done){

                                        let formData = new FormData();

                                        formData.append('image',file.attachments[0])

                                        formData.append('store_id',this.settings.store_id);

                                        let baseUrl = process.env.VUE_APP_BASE_URL

                                        fetch(baseUrl + '/crm/email/image/upload',{
                                            method: 'POST',
                                            headers: {
                                                'Accept': 'application/json'
                                            },
                                            body: formData
                                        }).then((response)=>{

                                            if(response.status >= 200 && response.status < 300){

                                                return response;

                                            }else{

                                                var error = new Error(response.statusText);

                                                error.response = response;

                                                throw error;

                                            }

                                        }).then(response =>{

                                            return response.json();
                                            
                                        }).then(data=>{

                                            let spacesEndpoint = process.env.VUE_APP_STORE_IMAGE_BASE_URL;

                                            this.$store.commit('settings_module/update_remaining_space',data.remaining_space.usage_space);

                                            done({ progress: 100, url: spacesEndpoint + this.settings.store_id + '/email-template/' + data.image });

                                        });

                                    }.bind(this));

                                }

                                this.emailIsSaving = false;

                                this.tempEmail = _.cloneDeep(this.emailsList[tempIndex]);

                            }else{

                                let emailContacts = [];
                                let emailGroups = [];

                                listedEmail.recipients.forEach((recipient)=>{

                                    if(recipient.group && recipient.contact){

                                        let foundIndex = emailGroups.findIndex(emailGroup => emailGroup.group.id == recipient.group.id);

                                        if(foundIndex == -1){

                                            let tempContact = recipient.contact

                                            tempContact.status = recipient.status

                                            tempContact.description = recipient.description

                                            let tempGroup = {
                                                group:recipient.group,
                                                contacts: [tempContact]
                                            }

                                            emailGroups.push(tempGroup);

                                        }else{

                                            let tempContact = recipient.contact

                                            tempContact.status == recipient.status

                                            tempContact.description = recipient.description

                                            emailGroups[foundIndex].contacts.push(tempContact)

                                        }

                                    }else if(!recipient.group && recipient.contact){

                                        recipient.contact.status = recipient.status

                                        recipient.contact.description = recipient.description

                                        emailContacts.push(recipient.contact);

                                    }

                                });

                                this.previewForm.id = listedEmail.id
                                this.previewForm.subject = listedEmail.subject
                                this.previewForm.type = listedEmail.email_type
                                this.previewForm.template_design = listedEmail.template_design
                                this.previewForm.template_html = listedEmail.template_html
                                this.previewForm.contacts = emailContacts
                                this.previewForm.groups = emailGroups
                                this.previewEmail.isOpen = true

                                this.checkedItems = []

                                if(this.$refs.emailPreviewTemplateEditor){

                                    this.$refs.emailPreviewTemplateEditor.editor.loadDesign(JSON.parse(listedEmail.template_design));

                                    this.$refs.emailPreviewTemplateEditor.editor.setMergeTags(this.mergeTags);

                                    this.$refs.emailPreviewTemplateEditor.editor.registerCallback('image',function(file,done){

                                        let formData = new FormData();

                                        formData.append('image',file.attachments[0])

                                        formData.append('store_id',this.settings.store_id);

                                        let baseUrl = process.env.VUE_APP_BASE_URL

                                        fetch(baseUrl + '/crm/email/image/upload',{
                                            method: 'POST',
                                            headers: {
                                                'Accept': 'application/json'
                                            },
                                            body: formData
                                        }).then((response)=>{

                                            if(response.status >= 200 && response.status < 300){

                                                return response;

                                            }else{

                                                var error = new Error(response.statusText);

                                                error.response = response;

                                                throw error;

                                            }

                                        }).then(response =>{

                                            return response.json();
                                            
                                        }).then(data=>{

                                            let spacesEndpoint = process.env.VUE_APP_STORE_IMAGE_BASE_URL;

                                            this.$store.commit('settings_module/update_remaining_space',data.remaining_space.usage_space);

                                            done({ progress: 100, url: spacesEndpoint + this.settings.store_id + '/email-template/' + data.image });

                                        });

                                    }.bind(this));

                                }

                                this.emailIsSaving = false;

                                this.tempEmail = _.cloneDeep(listedEmail);

                            }

                        }

                    }

                }

            }else{
                
                if(!this.tourIsActive){
                
                    if(listedEmail.id != -1){
                        
                        if(this.previewForm.id == -1){
                        
                            MessageBox.confirm(
                                "Are you sure? unsaved changes will be discarded", 
                                "Warning", 
                                {
                                    confirmButtonText: "Yes",
                                    cancelButtonText: "Cancel",
                                    type: "warning",
                                }
                            ).then(()=>{

                                let index = this.emailsList.findIndex((email) => email.id == -1);
                                
                                if(index != -1){

                                    this.emailsList.splice(index, 1);

                                }

                                if(!this.isEmailSaved && (this.tempEmail.id != listedEmail.id)){
                                
                                    let tempIndex = this.emailsList.findIndex(x=>x.id == this.tempEmail.id);

                                    if(tempIndex != -1){

                                        this.emailsList[tempIndex] = _.cloneDeep(this.storedEmails[tempIndex])

                                    }

                                }

                                if(this.tempEmail.id != '' && this.tempEmail.id == listedEmail.id){
                                    
                                    let tempIndex = this.emailsList.findIndex(x=>x.id == this.tempEmail.id);

                                    this.emailsList[tempIndex] = _.cloneDeep(this.storedEmails[tempIndex])

                                    let emailContacts = [];
                                    let emailGroups = [];

                                    this.emailsList[tempIndex].recipients.forEach((recipient)=>{

                                        if(recipient.group && recipient.contact){

                                            let foundIndex = emailGroups.findIndex(emailGroup => emailGroup.group.id == recipient.group.id);

                                            if(foundIndex == -1){

                                                let tempContact = recipient.contact

                                                tempContact.status = recipient.status

                                                tempContact.description = recipient.description

                                                let tempGroup = {
                                                    group:recipient.group,
                                                    contacts: [tempContact]
                                                }

                                                emailGroups.push(tempGroup);

                                            }else{

                                                let tempContact = recipient.contact

                                                tempContact.status == recipient.status

                                                tempContact.description = recipient.description

                                                emailGroups[foundIndex].contacts.push(tempContact)

                                            }

                                        }else if(!recipient.group && recipient.contact){

                                            recipient.contact.status = recipient.status

                                            recipient.contact.description = recipient.description

                                            emailContacts.push(recipient.contact);

                                        }

                                    });

                                    this.previewForm.id = this.emailsList[tempIndex].id
                                    this.previewForm.subject = this.emailsList[tempIndex].subject
                                    this.previewForm.type = this.emailsList[tempIndex].email_type
                                    this.previewForm.template_design = this.emailsList[tempIndex].template_design
                                    this.previewForm.template_html = this.emailsList[tempIndex].template_html
                                    this.previewForm.contacts = emailContacts
                                    this.previewForm.groups = emailGroups
                                    this.previewEmail.isOpen = true

                                    this.checkedItems = []

                                    if(this.$refs.emailPreviewTemplateEditor){

                                        this.$refs.emailPreviewTemplateEditor.editor.loadDesign(JSON.parse(this.emailsList[tempIndex].template_design));

                                        this.$refs.emailPreviewTemplateEditor.editor.setMergeTags(this.mergeTags);

                                        this.$refs.emailPreviewTemplateEditor.editor.registerCallback('image',function(file,done){

                                            let formData = new FormData();

                                            formData.append('image',file.attachments[0])

                                            formData.append('store_id',this.settings.store_id);

                                            let baseUrl = process.env.VUE_APP_BASE_URL

                                            fetch(baseUrl + '/crm/email/image/upload',{
                                                method: 'POST',
                                                headers: {
                                                    'Accept': 'application/json'
                                                },
                                                body: formData
                                            }).then((response)=>{

                                                if(response.status >= 200 && response.status < 300){

                                                    return response;

                                                }else{

                                                    var error = new Error(response.statusText);

                                                    error.response = response;

                                                    throw error;

                                                }

                                            }).then(response =>{

                                                return response.json();
                                                
                                            }).then(data=>{

                                                let spacesEndpoint = process.env.VUE_APP_STORE_IMAGE_BASE_URL;

                                                this.$store.commit('settings_module/update_remaining_space',data.remaining_space.usage_space);

                                                done({ progress: 100, url: spacesEndpoint + this.settings.store_id + '/email-template/' + data.image });

                                            });

                                        }.bind(this));

                                    }

                                    this.emailIsSaving = false;

                                    this.tempEmail = _.cloneDeep(this.emailsList[tempIndex]);

                                }else{

                                    let emailContacts = [];
                                    let emailGroups = [];

                                    listedEmail.recipients.forEach((recipient)=>{

                                        if(recipient.group && recipient.contact){

                                            let foundIndex = emailGroups.findIndex(emailGroup => emailGroup.group.id == recipient.group.id);

                                            if(foundIndex == -1){

                                                let tempContact = recipient.contact

                                                tempContact.status = recipient.status

                                                tempContact.description = recipient.description

                                                let tempGroup = {
                                                    group:recipient.group,
                                                    contacts: [tempContact]
                                                }

                                                emailGroups.push(tempGroup);

                                            }else{

                                                let tempContact = recipient.contact

                                                tempContact.status == recipient.status

                                                tempContact.description = recipient.description

                                                emailGroups[foundIndex].contacts.push(tempContact)

                                            }

                                        }else if(!recipient.group && recipient.contact){

                                            recipient.contact.status = recipient.status

                                            recipient.contact.description = recipient.description

                                            emailContacts.push(recipient.contact);

                                        }

                                    });

                                    this.previewForm.id = listedEmail.id
                                    this.previewForm.subject = listedEmail.subject
                                    this.previewForm.type = listedEmail.email_type
                                    this.previewForm.template_design = listedEmail.template_design
                                    this.previewForm.template_html = listedEmail.template_html
                                    this.previewForm.contacts = emailContacts
                                    this.previewForm.groups = emailGroups
                                    this.previewEmail.isOpen = true

                                    this.checkedItems = []

                                    if(this.$refs.emailPreviewTemplateEditor){

                                        this.$refs.emailPreviewTemplateEditor.editor.loadDesign(JSON.parse(listedEmail.template_design));

                                        this.$refs.emailPreviewTemplateEditor.editor.setMergeTags(this.mergeTags);

                                        this.$refs.emailPreviewTemplateEditor.editor.registerCallback('image',function(file,done){

                                            let formData = new FormData();

                                            formData.append('image',file.attachments[0])

                                            formData.append('store_id',this.settings.store_id);

                                            let baseUrl = process.env.VUE_APP_BASE_URL

                                            fetch(baseUrl + '/crm/email/image/upload',{
                                                method: 'POST',
                                                headers: {
                                                    'Accept': 'application/json'
                                                },
                                                body: formData
                                            }).then((response)=>{

                                                if(response.status >= 200 && response.status < 300){

                                                    return response;

                                                }else{

                                                    var error = new Error(response.statusText);

                                                    error.response = response;

                                                    throw error;

                                                }

                                            }).then(response =>{

                                                return response.json();
                                                
                                            }).then(data=>{

                                                let spacesEndpoint = process.env.VUE_APP_STORE_IMAGE_BASE_URL;

                                                this.$store.commit('settings_module/update_remaining_space',data.remaining_space.usage_space);

                                                done({ progress: 100, url: spacesEndpoint + this.settings.store_id + '/email-template/' + data.image });

                                            })

                                        }.bind(this));

                                    }

                                    this.emailIsSaving = false;

                                    this.tempEmail = _.cloneDeep(listedEmail);

                                }

                            }).catch(()=>{})

                        }else{

                            let index = this.emailsList.findIndex((email) => email.id == -1);
                                
                            if(index != -1){

                                this.emailsList.splice(index, 1);

                            }

                            if(!this.isEmailSaved && (this.tempEmail.id != listedEmail.id)){
                            
                                let tempIndex = this.emailsList.findIndex(x=>x.id == this.tempEmail.id);

                                if(tempIndex != -1){

                                    this.emailsList[tempIndex] = _.cloneDeep(this.storedEmails[tempIndex])

                                }

                            }

                            if(this.tempEmail.id != '' && this.tempEmail.id == listedEmail.id){
                                
                                let tempIndex = this.emailsList.findIndex(x=>x.id == this.tempEmail.id);

                                this.emailsList[tempIndex] = _.cloneDeep(this.storedEmails[tempIndex])

                                let emailContacts = [];
                                let emailGroups = [];

                                this.emailsList[tempIndex].recipients.forEach((recipient)=>{

                                    if(recipient.group && recipient.contact){

                                        let foundIndex = emailGroups.findIndex(emailGroup => emailGroup.group.id == recipient.group.id);

                                        if(foundIndex == -1){

                                            let tempContact = recipient.contact

                                            tempContact.status = recipient.status

                                            tempContact.description = recipient.description

                                            let tempGroup = {
                                                group:recipient.group,
                                                contacts: [tempContact]
                                            }

                                            emailGroups.push(tempGroup);

                                        }else{

                                            let tempContact = recipient.contact

                                            tempContact.status == recipient.status

                                            tempContact.description = recipient.description

                                            emailGroups[foundIndex].contacts.push(tempContact)

                                        }

                                    }else if(!recipient.group && recipient.contact){

                                        recipient.contact.status = recipient.status

                                        recipient.contact.description = recipient.description

                                        emailContacts.push(recipient.contact);

                                    }

                                });

                                this.previewForm.id = this.emailsList[tempIndex].id
                                this.previewForm.subject = this.emailsList[tempIndex].subject
                                this.previewForm.type = this.emailsList[tempIndex].email_type
                                this.previewForm.template_design = this.emailsList[tempIndex].template_design
                                this.previewForm.template_html = this.emailsList[tempIndex].template_html
                                this.previewForm.contacts = emailContacts
                                this.previewForm.groups = emailGroups
                                this.previewEmail.isOpen = true

                                this.checkedItems = []

                                if(this.$refs.emailPreviewTemplateEditor){

                                    this.$refs.emailPreviewTemplateEditor.editor.loadDesign(JSON.parse(this.emailsList[tempIndex].template_design));

                                    this.$refs.emailPreviewTemplateEditor.editor.setMergeTags(this.mergeTags);

                                    this.$refs.emailPreviewTemplateEditor.editor.registerCallback('image',function(file,done){

                                        let formData = new FormData();

                                        formData.append('image',file.attachments[0])

                                        formData.append('store_id',this.settings.store_id);

                                        let baseUrl = process.env.VUE_APP_BASE_URL

                                        fetch(baseUrl + '/crm/email/image/upload',{
                                            method: 'POST',
                                            headers: {
                                                'Accept': 'application/json'
                                            },
                                            body: formData
                                        }).then((response)=>{

                                            if(response.status >= 200 && response.status < 300){

                                                return response;

                                            }else{

                                                var error = new Error(response.statusText);

                                                error.response = response;

                                                throw error;

                                            }

                                        }).then(response =>{

                                            return response.json();
                                            
                                        }).then(data=>{

                                            let spacesEndpoint = process.env.VUE_APP_STORE_IMAGE_BASE_URL;

                                            this.$store.commit('settings_module/update_remaining_space',data.remaining_space.usage_space);

                                            done({ progress: 100, url: spacesEndpoint + this.settings.store_id + '/email-template/' + data.image });

                                        });

                                    }.bind(this));

                                }

                                this.emailIsSaving = false;

                                this.tempEmail = _.cloneDeep(this.emailsList[tempIndex]);

                            }else{

                                let emailContacts = [];
                                let emailGroups = [];

                                listedEmail.recipients.forEach((recipient)=>{

                                    if(recipient.group && recipient.contact){

                                        let foundIndex = emailGroups.findIndex(emailGroup => emailGroup.group.id == recipient.group.id);

                                        if(foundIndex == -1){

                                            let tempContact = recipient.contact

                                            tempContact.status = recipient.status

                                            tempContact.description = recipient.description

                                            let tempGroup = {
                                                group:recipient.group,
                                                contacts: [tempContact]
                                            }

                                            emailGroups.push(tempGroup);

                                        }else{

                                            let tempContact = recipient.contact

                                            tempContact.status == recipient.status

                                            tempContact.description = recipient.description

                                            emailGroups[foundIndex].contacts.push(tempContact)

                                        }

                                    }else if(!recipient.group && recipient.contact){

                                        recipient.contact.status = recipient.status

                                        recipient.contact.description = recipient.description

                                        emailContacts.push(recipient.contact);

                                    }

                                });

                                this.previewForm.id = listedEmail.id
                                this.previewForm.subject = listedEmail.subject
                                this.previewForm.type = listedEmail.email_type
                                this.previewForm.template_design = listedEmail.template_design
                                this.previewForm.template_html = listedEmail.template_html
                                this.previewForm.contacts = emailContacts
                                this.previewForm.groups = emailGroups
                                this.previewEmail.isOpen = true

                                this.checkedItems = []

                                if(this.$refs.emailPreviewTemplateEditor){

                                    this.$refs.emailPreviewTemplateEditor.editor.loadDesign(JSON.parse(listedEmail.template_design));

                                    this.$refs.emailPreviewTemplateEditor.editor.setMergeTags(this.mergeTags);

                                    this.$refs.emailPreviewTemplateEditor.editor.registerCallback('image',function(file,done){

                                        let formData = new FormData();

                                        formData.append('image',file.attachments[0])

                                        formData.append('store_id',this.settings.store_id);

                                        let baseUrl = process.env.VUE_APP_BASE_URL

                                        fetch(baseUrl + '/crm/email/image/upload',{
                                            method: 'POST',
                                            headers: {
                                                'Accept': 'application/json'
                                            },
                                            body: formData
                                        }).then((response)=>{

                                            if(response.status >= 200 && response.status < 300){

                                                return response;

                                            }else{

                                                var error = new Error(response.statusText);

                                                error.response = response;

                                                throw error;

                                            }

                                        }).then(response =>{

                                            return response.json();
                                            
                                        }).then(data=>{

                                            let spacesEndpoint = process.env.VUE_APP_STORE_IMAGE_BASE_URL;

                                            this.$store.commit('settings_module/update_remaining_space',data.remaining_space.usage_space);

                                            done({ progress: 100, url: spacesEndpoint + this.settings.store_id + '/email-template/' + data.image });

                                        });

                                    }.bind(this));

                                }

                                this.emailIsSaving = false;

                                this.tempEmail = _.cloneDeep(listedEmail);

                            }

                        }

                    }

                }

            }

        },
        previewSearchedEmail(listedEmail,i){

            let index = this.emailsList.findIndex((email) => email.id == -1);
                            
            if(index != -1){

                this.emailsList.splice(index, 1);

            }

            let emailContacts = [];
            let emailGroups = [];

            listedEmail.recipients.forEach((recipient)=>{

                if(recipient.group && recipient.contact){

                    let foundIndex = emailGroups.findIndex(emailGroup => emailGroup.group.id == recipient.group.id);

                    if(foundIndex == -1){

                        let tempContact = recipient.contact

                        tempContact.status = recipient.status

                        tempContact.description = recipient.description

                        let tempGroup = {
                            group:recipient.group,
                            contacts: [tempContact]
                        }

                        emailGroups.push(tempGroup);

                    }else{

                        let tempContact = recipient.contact

                        tempContact.status == recipient.status

                        tempContact.description = recipient.description

                        emailGroups[foundIndex].contacts.push(tempContact)

                    }

                }else if(!recipient.group && recipient.contact){

                    recipient.contact.status = recipient.status

                    recipient.contact.description = recipient.description

                    emailContacts.push(recipient.contact);

                }

            });

            this.previewForm.id = listedEmail.id
            this.previewForm.subject = listedEmail.subject
            this.previewForm.type = listedEmail.email_type
            this.previewForm.template_design = listedEmail.template_design
            this.previewForm.template_html = listedEmail.template_html
            this.previewForm.contacts = emailContacts
            this.previewForm.groups = emailGroups
            this.previewEmail.isOpen = true

            this.checkedItems = []

            if(this.$refs.emailPreviewTemplateEditor){

                this.$refs.emailPreviewTemplateEditor.editor.loadDesign(JSON.parse(listedEmail.template_design));

                this.$refs.emailPreviewTemplateEditor.editor.setMergeTags(this.mergeTags);

                this.$refs.emailPreviewTemplateEditor.editor.registerCallback('image',function(file,done){

                    let formData = new FormData();

                    formData.append('image',file.attachments[0])

                    formData.append('store_id',this.settings.store_id);

                    let baseUrl = process.env.VUE_APP_BASE_URL

                    fetch(baseUrl + '/crm/email/image/upload',{
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json'
                        },
                        body: formData
                    }).then((response)=>{

                        if(response.status >= 200 && response.status < 300){

                            return response;

                        }else{

                            var error = new Error(response.statusText);

                            error.response = response;

                            throw error;

                        }


                    }).then(response =>{

                        return response.json();
                        
                    }).then(data=>{

                        let spacesEndpoint = process.env.VUE_APP_STORE_IMAGE_BASE_URL;

                        this.$store.commit('settings_module/update_remaining_space',data.remaining_space.usage_space);

                        done({ progress: 100, url: spacesEndpoint + this.settings.store_id + '/email-template/' + data.image });

                    });

                }.bind(this));

            }

            this.emailIsSaving = false;

            this.tempEmail = _.cloneDeep(listedEmail);

        },
        closePreview(){

            if(this.previewForm.isTouched){
            
                MessageBox.confirm(
                    "Do you want to close? Entered information will be discarded.", 
                    "Warning", 
                    {
                        confirmButtonText: "Yes",
                        cancelButtonText: "Cancel",
                        type: "warning",
                    }
                ).then(async () => {
                    
                    let index = this.emailsList.findIndex((email) => email.id == -1);
                            
                    if(index != -1){

                        this.emailsList.splice(index, 1);

                    }

                    if(!this.isEmailSaved){

                        let tempIndex = this.emailsList.findIndex(x=>x.id == this.tempEmail.id);

                        this.emailsList[tempIndex] = _.cloneDeep(this.storedEmails[tempIndex])

                    }

                    // if(this.$refs.emailPreviewTemplateEditor){

                    //     this.$refs.emailPreviewTemplateEditor.editor.loadDesign(this.defaultTemplateDesign);

                    // }

                    this.previewForm = {
                        id:'',
                        subject:'',
                        type:'',
                        template_design:'',
                        template_html:'',
                        contacts:[],
                        groups:[],
                        isOpen:false,
                        pending:false,
                        isTouched:false,
                    }

                    this.tempEmail = this.previewForm

                    this.isEmailSaved = false

                    this.emailIsSaving = false

                    if(this.$refs.emailObserver){

                        this.$refs.emailObserver.reset();

                    }

                }).catch(()=>{})

            }else{

                let index = this.emailsList.findIndex((email) => email.id == -1);
                            
                if(index != -1){

                    this.emailsList.splice(index, 1);

                }

                if(!this.isEmailSaved){

                    let tempIndex = this.emailsList.findIndex(x=>x.id == this.tempEmail.id);

                    this.emailsList[tempIndex] = _.cloneDeep(this.storedEmails[tempIndex])

                }

                // if(this.$refs.emailPreviewTemplateEditor){
                    
                //     this.$refs.emailPreviewTemplateEditor.editor.loadDesign(this.defaultTemplateDesign);

                // }

                this.previewForm = {
                    id:'',
                    subject:'',
                    type:'',
                    template_design:'',
                    template_html:'',
                    contacts:[],
                    groups:[],
                    isOpen:false,
                    pending:false,
                    isTouched:false,
                }

                this.tempEmail = this.previewForm

                this.isEmailSaved = false

                this.emailIsSaving = false

                if(this.$refs.emailObserver){

                    this.$refs.emailObserver.reset();

                }

            }

        },
        resetPreview(){

            let index = this.emailsList.findIndex((email) => email.id == -1);
                            
            if(index != -1){

                this.emailsList.splice(index, 1);

            }

            if(!this.isEmailSaved){

                let tempIndex = this.emailsList.findIndex(x=>x.id == this.tempEmail.id);

                if(tempIndex != -1){

                    this.emailsList[tempIndex] = _.cloneDeep(this.storedEmails[tempIndex])

                }

            }

            this.previewForm = {
                id:'',
                subject:'',
                type:'',
                template_design:'',
                template_html:'',
                contacts:[],
                groups:[],
                isOpen:false,
                pending:false,
                isTouched:false,
            }

            this.tempEmail = this.previewForm

            this.isEmailSaved = false

            this.emailIsSaving = false

            if(this.$refs.emailObserver){

                this.$refs.emailObserver.reset();

            }

        },
        createEmail(){

            if(this.emailsLoading){

                this.$notify({
                    type: "info",
                    title: "Please Wait",
                    message: "Emails are being retrieved.",
                });

                return

            }

            if(this.emailIsSaving){

                this.$notify({
                    type: "info",
                    title: "Please Wait",
                    message: "An email is currently being processed.",
                });

                return

            }

            let index = this.emailsList.findIndex(x=>x.id == -1);

            if(index != -1){

                // this.$notify({
                //   type: "error",
                //   title: "Can't Add New Email",
                //   message: "you have an unsaved email in the list",
                // });

                return
            }

            this.previewForm = {
                id:-1,
                subject:'',
                type:'',
                template_design:'',
                template_html:'',
                contacts:[],
                groups:[],
                isOpen:false,
                pending:false,
                isTouched:false,
            }

            let tempEmail = this.previewForm

            this.emailsList.unshift(tempEmail);

            if(this.$refs.emailObserver){

                this.$refs.emailObserver.reset();

            }

            this.groupedContacts = {
                group:{},
                contacts:[],
                isOpen:false,
            }

            if(this.$refs.emailPreviewTemplateEditor){

                this.$refs.emailPreviewTemplateEditor.editor.loadDesign(this.defaultTemplateDesign);

                this.$refs.emailPreviewTemplateEditor.editor.setMergeTags(this.mergeTags);

                this.$refs.emailPreviewTemplateEditor.editor.registerCallback('image',function(file,done){

                    let formData = new FormData();

                    formData.append('image',file.attachments[0])

                    formData.append('store_id',this.settings.store_id);

                    let baseUrl = process.env.VUE_APP_BASE_URL

                    fetch(baseUrl + '/crm/email/image/upload',{
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json'
                        },
                        body: formData
                    }).then((response)=>{

                        if(response.status >= 200 && response.status < 300){

                            return response;

                        }else{

                            var error = new Error(response.statusText);

                            error.response = response;

                            throw error;

                        }


                    }).then(response =>{

                        return response.json();
                        
                    }).then(data=>{

                        let spacesEndpoint = process.env.VUE_APP_STORE_IMAGE_BASE_URL;

                        this.$store.commit('settings_module/update_remaining_space',data.remaining_space.usage_space);

                        done({ progress: 100, url: spacesEndpoint + this.settings.store_id + '/email-template/' + data.image });

                    })

                }.bind(this));

            }

            this.checkedItems = []

        },
        //preview form open/close & reset end

        //email recipients (data table) start
        openEmailRecipientsModal(listedEmail,index){

            this.emailRecipientsModal = {
                email:{},
                groups:[],
                contacts:[],
                isOpen:false
            }

            this.emailRecipientGroupContact = {
                group:{},
                contacts:[],
                isOpen:false,
            }

            let emailContacts = [];
            let emailGroups = [];

            listedEmail.recipients.forEach((recipient)=>{

                if(recipient.group && recipient.contact){

                    let foundIndex = emailGroups.findIndex(emailGroup => emailGroup.group.id == recipient.group.id);

                    if(foundIndex == -1){

                        let tempContact = recipient.contact

                        tempContact.status = recipient.status

                        tempContact.description = recipient.description

                        let tempGroup = {
                            group:recipient.group,
                            contacts: [tempContact]
                        }

                        emailGroups.push(tempGroup);

                    }else{

                        let tempContact = recipient.contact

                        tempContact.status == recipient.status

                        tempContact.description = recipient.description

                        emailGroups[foundIndex].contacts.push(tempContact)

                    }

                }else if(!recipient.group && recipient.contact){

                    recipient.contact.status = recipient.status

                    recipient.contact.description = recipient.description

                    emailContacts.push(recipient.contact);

                }

            });

            this.emailRecipientsModal = {
                email: listedEmail,
                contacts: emailContacts,
                groups: emailGroups,
                isOpen: true
            }

        },
        openEmailRecipientGroupContactsModal(listedGroup,index){

            this.emailRecipientGroupContact = {
                group: listedGroup.group,
                contacts: listedGroup.contacts,
                isOpen: true
            }

        },
        //email recipients (data table) end

        //contacts start
        openContactsModal(){

            this.contactsModal.selectedContacts = []

            this.contactsModal.isOpen = true

            this.contactsModal.selectedContacts = _.cloneDeep(this.previewForm.contacts);

        },
        closeContactsModal(){

            this.previewForm.contacts = _.cloneDeep(this.contactsModal.selectedContacts)

            this.searchedContact = '';

            this.searchContacts('simple',1);

            this.contactsModal.isOpen = false

        },
        
        contactsPaginateChangeHandle(currentPage, pageSize){

            this.searchContacts('search',currentPage);

        },
        async searchContacts(operation, page){

            if(operation == 'simple'){

                try{
                    let res = await this.$axios.get('/crm/contact/all?grouped=0',{
                        params:{
                            search: this.searchedContact ? this.searchedContact : null,
                            offset: 10,
                            page: page ? page : null,
                        },
                    });
                    if(res.data.status_code == "1460"){
                        
                        this.contactsModal.list = _.cloneDeep(res.data.contacts.data);

                        this.contactsPagination = {
                            current_page: page,
                            total: res.data.contacts.last_page,
                            previous_page:0,
                            last_page: res.data.contacts.last_page
                        }

                    }
                }catch(error){

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.response ? error.response.data.message : error.message,
                    });
                    
                }

            }else if(operation == 'search'){

                this.contactsSearchLoader = true;

                clearTimeout(this.timer);

                this.timer = setTimeout( async ()=>{
                    try{
                        let res = await this.$axios.get(`/crm/contact/all?grouped=0`,{
                            params:{
                                search: this.searchedContact ? this.searchedContact : null,
                                offset: 10,
                                page: page ? page : null,
                            },
                        });
                        if(res.data.status_code == "1460"){

                            this.contactsModal.list = _.cloneDeep(res.data.contacts.data);

                            this.contactsPagination = {
                                current_page: page,
                                total: res.data.contacts.last_page,
                                previous_page:0,
                                last_page: res.data.contacts.last_page
                            }

                        }
                    }catch(error){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response ? error.response.data.message : error.message,
                        });

                    }finally{

                        this.contactsSearchLoader = false;

                    }

                },500);


            }

        },
        generateContactImageSrc(contact){
            
            if(contact.image){

                if(contact.is_own_image == 0){

                    if(typeof(contact.image) == "string"){

                        return this.base_image_url + this.settings.store_id + '/user/' + contact.image;

                    }else{

                        return contact.image_src;

                    }

                }else{

                    if(typeof(contact.image) == "string"){

                        return this.base_image_url + this.settings.store_id + '/crm-contact/' + contact.image;

                    }else{

                        return contact.image_src;

                    }

                }

            }else if(contact.image_src){

                if(contact.is_own_image == 1){
                    
                    return this.base_image_url + this.settings.store_id + '/crm-contact/' + contact.image_src;
                    
                }else{

                    return this.base_image_url + this.settings.store_id + '/user/' + contact.image_src;

                }

            }

        },
        //contacts end

        //preview form contacts methods start
        removeFormContactHandle(listedContact,index){

            MessageBox.confirm(
                'Are you sure you want to remove the contact?',
                'Warning',
                {
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'Cancel',
                    type: 'warning',
                }
            ).then(async ()=>{

                this.previewForm.contacts.splice(index,1);

            }).catch(() => {})

        },
        //preview form contacts methods start

        //groups start
        openGroupsModal(){

            this.groupsModal.selectedGroups = [];

            this.groupsModal.isOpen = true

            this.groupsModal.selectedGroups = _.cloneDeep(this.previewForm.groups);

        },
        closeGroupsModal(){

            this.previewForm.groups = _.cloneDeep(this.groupsModal.selectedGroups);

            this.searchedGroup = ''

            this.searchGroups('simple',1);

            this.groupsModal.isOpen = false

        },
        openGroupedContactsModal(listedGroup,index){

            this.groupedContacts = {
                group:{},
                contacts:[],
                isOpen:false,
            }

            this.groupedContacts.group = listedGroup

            this.groupedContacts.contacts = listedGroup.contacts

            this.groupedContacts.isOpen = true

        },

        groupsPaginateChangeHandle(currentPage, pageSize){

            this.searchGroups('search',currentPage);

        },
        async searchGroups(operation, page){

            if(operation == 'simple'){

                try{
                    let res = await this.$axios.get('crm/group/all',{
                        params:{
                            search: this.searchedGroup ? this.searchedGroup : null,
                            offset: 10,
                            page: page ? page : null,
                        },
                    });
                    if(res.data.status_code == "1440"){
                        
                        this.groupsModal.list = _.cloneDeep(res.data.groups.data);

                        this.groupsPagination = {
                            current_page: page,
                            total: res.data.groups.last_page,
                            previous_page:0,
                            last_page: res.data.groups.last_page
                        }

                    }
                }catch(error){

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.response ? error.response.data.message : error.message,
                    });
                    
                }

            }else if(operation == 'search'){

                this.groupsSearchLoader = true;

                clearTimeout(this.timer);

                this.timer = setTimeout( async ()=>{
                    try{
                        let res = await this.$axios.get(`crm/group/all`,{
                            params:{
                                search: this.searchedGroup ? this.searchedGroup : null,
                                offset: 10,
                                page: page ? page : null,
                            },
                        });
                        if(res.data.status_code == "1440"){

                            this.groupsModal.list = _.cloneDeep(res.data.groups.data);

                            this.groupsPagination = {
                                current_page: page,
                                total: res.data.groups.last_page,
                                previous_page:0,
                                last_page: res.data.groups.last_page
                            }

                        }
                    }catch(error){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response ? error.response.data.message : error.message,
                        });

                    }finally{

                        this.groupsSearchLoader = false;

                    }

                },500);


            }

        },

        //groups end

        //preview form groups methods start
        removeFormGroupHandle(listedGroup,index){

            MessageBox.confirm(
                'Are you sure you want to remove the group?',
                'Warning',
                {
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'Cancel',
                    type: 'warning',
                }
            ).then(async ()=>{

                this.previewForm.groups.splice(index,1);

            }).catch(() => {})

        },
        //preview form groups methods start
        

        //send email start
        async sendEmailHandle(templateData){

            let formData = new FormData();

            formData.append('subject',this.previewForm.subject);
            formData.append('email_type',this.previewForm.type);

            formData.append('template_design',JSON.stringify(templateData.design));
            formData.append('template_html',templateData.html)

            let recipients = {
                contacts: this.previewForm.contacts.map((contact)=>{
                    return contact.id
                }),
                groups: this.previewForm.groups.map((group) =>{
                    return group.id
                })
            }

            formData.append('recipients',JSON.stringify(recipients))

            this.emailIsSaving = true
            try{
                let res = await this.$axios.post('/crm/email/send',formData);
                if(res.data.status_code == "1481"){
                    
                    this.emailIsSaving = false

                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: res.data.message,
                    });

                    this.isEmailSaved = true

                    this.resetPreview();

                    this.$store.commit('emails_module/add_email_list_item',res.data.email);

                }
            }catch(error){

                this.emailIsSaving = false

                if(error.response){

                    if(error.response.data.error.includes('delivery is not available') ||
                    error.response.data.error.includes('error was encountered') ||
                    error.response.data.error.includes("deleted or isn't available")){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error,
                        });

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }

                }else{

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                    });

                }

            }

        },
        //send email end


        //template selection methods start
        openTemplateSelectionModal(){

            this.templateSelectionModal.isOpen = true

        },
        closeTemplateSelectionModal(){

            if(!this.templateSelectionModal.template){
                
                this.templateSelectionModal = {
                    template:{},
                    isOpen:false,
                }

                this.$refs.emailPreviewTemplateEditor.editor.loadDesign(this.defaultTemplateDesign)

            }else{

                if(this.templateSelectionModal.template.template_design){
                    
                    this.$refs.emailPreviewTemplateEditor.editor.loadDesign(JSON.parse(this.templateSelectionModal.template.template_design))

                    this.templateSelectionModal.isOpen = false

                }else{
                    
                    this.templateSelectionModal.isOpen = false

                }

            }

        },
        selectEmailTemplateHandle(listedTemplate){

            if(this.templateSelectionModal.template && listedTemplate.id == this.templateSelectionModal.template.id){
                
                this.templateSelectionModal.template = null

                this.$refs.emailPreviewTemplateEditor.editor.loadDesign(this.defaultTemplateDesign)

            }else{

                this.$refs.emailPreviewTemplateEditor.editor.loadDesign(JSON.parse(listedTemplate.template_design))

                this.templateSelectionModal.template = listedTemplate

            }

        },
        //template selection methods start


        //email preview editor methods start
        emailPreviewEmailEditorLoaded(){

            if(this.previewForm.id == -1){

                this.$refs.emailPreviewTemplateEditor.editor.loadDesign(this.defaultTemplateDesign);

                this.$refs.emailPreviewTemplateEditor.editor.setMergeTags(this.mergeTags);

                this.$refs.emailPreviewTemplateEditor.editor.registerCallback('image',function(file,done){

                    let formData = new FormData();

                    formData.append('image',file.attachments[0])

                    formData.append('store_id',this.settings.store_id);

                    let baseUrl = process.env.VUE_APP_BASE_URL

                    fetch(baseUrl + '/crm/email/image/upload',{
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json'
                        },
                        body: formData
                    }).then((response)=>{

                        if(response.status >= 200 && response.status < 300){

                            return response

                        }else{

                            var error = new Error(response.statusText)
                            error.response = response
                            throw error

                        }

                    }).then(response =>{

                        return response.json()
                        
                    }).then(data=>{

                        let spacesEndpoint = process.env.VUE_APP_STORE_IMAGE_BASE_URL

                        this.$store.commit('settings_module/update_remaining_space',data.remaining_space.usage_space);

                        done({ progress: 100, url: spacesEndpoint + this.settings.store_id + '/email-template/' + data.image })

                    })

                }.bind(this));


            }else{


            }

        },
        emailPreviewEmailEditorReady(){
        },
        //email preview editor methods end
        
        
        //check/uncheck & bulk action start
        checkedItemHandle(emailId){
            
            if(!this.tourIsActive && emailId != -1 && !this.previewForm.id){
                
                let index = this.checkedItems.findIndex(item => item == emailId);
                
                if(index != -1){

                    this.checkedItems.splice(index,1)

                }else{

                    this.checkedItems.push(emailId)

                }

            }

        },
        toggleIsCheckedAll(){

            if(!this.tourIsActive){

                if(this.checkedItems.length == this.emailsList.length){
                    
                    this.checkedItems = [];

                }else if(this.checkedItems.length < this.emailsList.length){
                    
                    this.checkedItems = [];
                    this.emailsList.forEach((group) => {

                        this.checkedItems.push(group.id);

                    });
                    
                }

            }

        },
        clearSelection(){

          this.checkedItems = [];

        },
        //check/uncheck & bulk action end

        //tour methods start
        startEmailsTour(){

            this.$tours['emailsTour'].start();

            this.tourIsActive = true

            if(this.previewForm.id != ''){

                this.resetPreview();

            }

        },
        handleNextStep(currentStep){

            if(currentStep + 1 == 7){

                this.createEmail();

                document.querySelector(".tableActionBtns").style.position = "static"

                document.querySelector(".productSlideBox").style.overflowY  = "hidden"

            }

            if(currentStep >= 15){

                document.querySelector(".tableActionBtns").style.position = "sticky"

            }

            if(currentStep + 1 == 18){
                
                this.expand()

            }

            if(currentStep + 1 == 19){
                
                if(this.$refs.viewInputForm.classList.contains('fullWidthSlide')){
                
                    this.expand()
                
                }

            }

        },
        handlePreviousStep(currentStep){

            if(currentStep == 7){

                this.resetPreview();

                document.querySelector(".tableActionBtns").style.position = "sticky"

                document.querySelector(".productSlideBox").style.overflowY  = "auto"

            }

            if(currentStep <= 16){

                document.querySelector(".tableActionBtns").style.position = "static"

            }

            if(currentStep == 19){
                
                this.expand()

            }

            if(currentStep == 18){
                
                if(this.$refs.viewInputForm.classList.contains('fullWidthSlide')){
                
                    this.expand()
                
                }

            }

        },
        handleTourStop(){

            document.querySelector(".tableActionBtns").style.position = "sticky"

            document.querySelector(".productSlideBox").style.overflowY  = "auto"

            this.resetPreview()

            this.tourIsActive = false

        },
        handleTourFinish(){

            document.querySelector(".tableActionBtns").style.position = "sticky"

            document.querySelector(".productSlideBox").style.overflowY  = "auto"

            this.resetPreview()

            this.tourIsActive = false

        },
        handleTourSkip(){

            document.querySelector(".tableActionBtns").style.position = "sticky"

            document.querySelector(".productSlideBox").style.overflowY  = "auto"

            this.resetPreview()

            this.tourIsActive = false

        },
        //tour methods end

        //email search start
        searchEmail(){

            this.resetPreview();

            this.searchLoader = true
            this.emailsLoading = true

            clearTimeout(this.timer)

            this.timer = setTimeout(() => {

                if(this.searchedItem.length > 0){
                    
                    let payload = {
                        keyword: this.searchedItem,
                    }

                    this.$store.commit('emails_module/update_searched_keyword',payload)
                    this.$store.dispatch('emails_module/fetchStoreEmailsRequest',{ size:15, page:1, loadData:'new'})

                }else{

                    this.$store.commit('emails_module/remove_searched_keyword')
                    this.$store.dispatch('emails_module/fetchStoreEmailsRequest',{ size:15, page:1, loadData:'new'})

                }

            }, 500)

        },
        clearSearch(){

            this.searchedItem = ''

            this.searchEmail();

        },
        //email search end

        //other methods start
        isDeletingItem(id){

            let find = this.deletingItems.find(x=>x == id);

            if(find){

                return true;

            }else{

                return false;

            }

        },
        expand(){

            this.$refs.viewInputForm.classList.toggle('fullWidthSlide');

        },
        emailKeyEvent(e){

            if(e.key == 'Escape'){

                if(this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.$refs.viewInputForm.classList.remove('fullWidthSlide');

                }else if(this.$refs.viewInputForm.classList.contains('activeRow')){

                    this.closePreview();

                }
            }

        },

        //image loading error handling
        handleImageError(e){

            e.target.src = '/images/image_not_found.png'

        },

    },
    mounted(){

        this.$refs.emailsTable.addEventListener('scroll', this.handleScrollEvent)

        window.addEventListener('keydown',this.emailKeyEvent)

        window.addEventListener('beforeunload', (event) => {

            let index = this.emailsList.findIndex((email) => email.id == -1);

            if(this.previewForm.id == -1 || index != -1){

                event.returnValue = "Are you sure you want to leave? entered information will be lost";

            }

        });

        if(this.$route.query.search){
        
            this.searchedItem = this.$route.query.search

            if(this.$route.query.preview == 1){

                this.isMasterSearched = true

            }

            this.$router.replace({'query': null});

            this.searchEmail();

        }

    },
    async beforeMount(){

        if((!this.storedEmails || this.storedEmails.length == 0) && this.emailsSearchKeyword.length == 0){
            
            this.emailsLoading = true;

            this.$store.dispatch('emails_module/fetchStoreEmailsRequest',{ size: 15 ,page: 1, loadData:'new' });

        }else{

            this.emailsList = _.cloneDeep(this.storedEmails)

            this.emailsLoading = false;

        }

        if(this.emailsSearchKeyword.length > 0){

            this.searchedItem = this.emailsSearchKeyword

        }


        if(!this.storedEmailTemplates || this.storedEmailTemplates.length == 0){

            this.$store.dispatch('email_templates_module/fetchStoreEmailTemplatesRequest');

        }else{

            this.emailTemplatesModal.list = _.cloneDeep(this.storedEmailTemplates)

        }

        //getting groups
        this.searchGroups('simple',1);

        //getting contacts
        this.searchContacts('simple',1);

    },
    beforeDestroy(){

        window.removeEventListener("keydown", this.emailKeyEvent);

    },
    beforeRouteLeave(to, from, next){

        let index = null;

        this.emailsList.forEach((email) => {

            if(email.id == -1){

                index = this.emailsList.indexOf(email);

            }
            
        });

        if(window.innerWidth <= 1024){

            document.body.classList.remove('activeIpad');
            document.body.classList.add('hideSideBar');

        }

        if((this.previewForm.id || index != null) && to.fullPath != '/login'){

            MessageBox.confirm(
                'You may have unsaved changes, Proceeding will discard them',
                'Confirm',
                {
                    distinguishCancelAndClose: true,
                    confirmButtonText: 'Process',
                    cancelButtonText: 'Stay',
                }
            ).then(()=>{
                
                this.resetPreview();

                next();

            }).catch(()=>{
                
            })

        }else{

            next();

        }

    },
}
window.myFunction = function (event){

    // reset all menu items
    document.querySelectorAll(".sectionTabsLink ul li a.active")
    .forEach(function (item) {

        item.classList.remove("active");
        
    });
    // mark as active selected menu item

    event.target.classList.add("active");

};
</script>

<style>

</style>